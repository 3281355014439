import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'ContentSection/';

const getAllContentSections = async (isActive) => axiosInstance.get(`${ENDPOINTORIGIN}GetAllContentSections?isActive=${isActive}`, constants.REQUIRE_INTERCEPTORS);

const getContentSectionById = async (contentSectionId) => axiosInstance.get(`${ENDPOINTORIGIN}GetContentSectionById?contentSectionId=${contentSectionId}`, constants.REQUIRE_INTERCEPTORS);

const createContentSection = async (contentSection) => axiosInstance.post(`${ENDPOINTORIGIN}CreateContentSection`, contentSection, constants.REQUIRE_INTERCEPTORS);

const updateContentSection = async (contentSection) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateContentSection`, contentSection, constants.REQUIRE_INTERCEPTORS);

const deleteContentSection = async (contentSectionId) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteContentSection?id=${contentSectionId}`, constants.REQUIRE_INTERCEPTORS);

const getContentSectionOptions = async (pageId, type) => axiosInstance.get(`${ENDPOINTORIGIN}GetContentSectionOptions?pageId=${pageId}&type=${type}`, constants.REQUIRE_INTERCEPTORS);

const getContentSectionOptionsForBlog = async (blogId, type) => axiosInstance.get(`${ENDPOINTORIGIN}GetContentSectionOptionsForBlog?blogId=${blogId}&type=${type}`, constants.REQUIRE_INTERCEPTORS);

const uploadImageToContentSection = async (file) => axiosInstance.post(`${ENDPOINTORIGIN}UploadImageToContentSection`, file, constants.REQUIRE_INTERCEPTORS);

export {
  getAllContentSections,
  getContentSectionById,
  createContentSection,
  updateContentSection,
  deleteContentSection,
  getContentSectionOptions,
  getContentSectionOptionsForBlog,
  uploadImageToContentSection
};