/* eslint-disable no-nested-ternary */
// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Image from './Image';

const MobileHeader = (props) => {
  const {
    backgroundColor,
    leftItemImageSource,
    leftItemImageTo,
    leftItemImageAlt,
    leftItem,
    leftItemTo,
    leftItemOnClick,
    rightItem,
    // rightItemTo,
    rightItemOnClick,
    hasBottomShadow
  } = props;

  const history = useHistory();

  const navigateToPath = (path) => {
    history.push(path);
  };

  return (
    <CustomBlock className={`mobile-header ${backgroundColor} ${hasBottomShadow ? 'with-shadow' : ''}`}>
      <CustomBlock className="left-item-block">
        {
          leftItemImageSource
            ? (
              <Image
                to={leftItemImageTo}
                source={leftItemImageSource}
                className="image"
                alt={leftItemImageAlt}
              />
            ) : leftItemTo
              ? (
                <button onClick={() => navigateToPath(leftItemTo)}>
                  {leftItem}
                </button>
              )
              : (
                <button onClick={leftItemOnClick}>
                  {leftItem}
                </button>
              )
        }
      </CustomBlock>

      <CustomBlock className="right-item-block">
        <button onClick={rightItemOnClick}>
          {rightItem}
        </button>
      </CustomBlock>
    </CustomBlock>
  );
};

MobileHeader.propTypes = {
  // MAIN PROPS
  backgroundColor: PropTypes.string,

  // LEFT ITEM PROPS
  leftItem: PropTypes.any,
  leftItemTo: PropTypes.string,
  leftItemOnClick: PropTypes.func,
  leftItemImageTo: PropTypes.string,
  leftItemImageSource: PropTypes.string,
  leftItemImageAlt: PropTypes.string,

  // RIGHT ITEM PROPS
  rightItem: PropTypes.any,
  // rightItemTo: PropTypes.string,
  rightItemOnClick: PropTypes.func,

  // BOOLEAN PROPS
  hasBottomShadow: PropTypes.bool,
};

MobileHeader.defaultProps = {
  // MAIN PROPS
  backgroundColor: 'white--sbg',

  // LEFT ITEM PROPS
  leftItem: null,
  leftItemTo: null,
  leftItemOnClick: () => { },
  leftItemImageTo: null,
  leftItemImageSource: '',
  leftItemImageAlt: 'Image',

  // RIGHT ITEM PROPS
  rightItem: null,
  // rightItemTo: null,
  rightItemOnClick: () => { },

  // BOOLEAN PROPS
  hasBottomShadow: true,
};

export default MobileHeader;