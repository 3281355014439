/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable react/no-array-index-key */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// ICONS
// CUSTOM COMPONENTS
import Section from '../../../../../components/Section';
import ContentBlock from '../../../../../components/ContentBlock';
import CustomBlock from '../../../../../components/CustomBlock';
import ContentHeader from '../../../../../components/ContentHeader';
import Button from '../../../../../components/Button';
import InputBlock from '../../../../../components/InputBlock';
import TextAreaBlock from '../../../../../components/TextAreaBlock';
import Overlay from '../../../../../components/Overlay';
// ASSETS
// SERVICES AND HELPERS
import * as pushNotificationHistoryService from '../../../../../services/pushNotification/pushNotificationHistoryService';
// REDUX
import * as auth from '../../../../../redux/authRedux';
import * as alert from '../../../../../redux/alertToastRedux';

const initialModel = {
  title: '',
  message: ''
};

const PushNotificationHistoryListingPage = (props) => {
  const { showAlert } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [model, setModel] = useState(initialModel);
  const [notificationHistory, setNotificationHistory] = useState([]);

  useEffect(() => {
    getAllHistory();
  }, []);

  const getAllHistory = () => {
    setIsLoading(true);
    pushNotificationHistoryService.getPushNotificationHistory().then((res) => {
      setNotificationHistory(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const createNotification = () => {
    setIsLoading(true);
    pushNotificationHistoryService.sendPushNotifications(model.title, model.message).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      getAllHistory();
      setModel(initialModel);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true,
  };

  const columns = [
    {
      name: 'title',
      label: 'Title',
      options: columnOptions,
    },
    {
      name: 'message',
      label: 'Message',
      options: columnOptions,
    },
    {
      name: 'numberOfDevicesNotificationSentTo',
      label: 'No# of Devices Sent to',
      options: columnOptions,
    },
    {
      name: 'createdBy',
      label: 'Created By',
      options: columnOptions,
    },
    {
      name: 'dateCreated',
      label: 'Date Created',
      options: columnOptions,
    },
  ];

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Push Notification"
                headerSize="lg"
              />
              <CustomBlock>
                <InputBlock
                  label="Title"
                  value={model.title}
                  onChange={(e) => {
                    setModel({
                      ...model,
                      title: e.target.value
                    });
                  }}
                />
              </CustomBlock>
              <CustomBlock>
                <TextAreaBlock
                  label="Message"
                  value={model.message}
                  onChange={(e) => {
                    setModel({
                      ...model,
                      message: e.target.value
                    });
                  }}
                />
              </CustomBlock>
              <CustomBlock className="flex--reverse">
                <Button
                  text="Create Notification"
                  isDisabled={!(model.message && model.title)}
                  className={(model.message && model.title) ? 'info--bg' : 'grey--bg'}
                  onClick={() => {
                    if (model.message && model.title) {
                      createNotification();
                    }
                  }}
                />
              </CustomBlock>
              <CustomBlock>
                <MUIDataTable
                  data={notificationHistory}
                  columns={columns}
                  title="Push Notification History"
                  options={{
                    selectableRows: 'none',
                    download: true,
                    print: false,
                    rowsPerPage: 100,
                    jumpToPage: true,
                    textLabels: { pagination: { jumpToPage: 'Page No:' } }
                  }}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...auth.actions, ...alert.actions })(PushNotificationHistoryListingPage);