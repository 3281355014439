/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-return-await */
/* eslint-disable no-shadow */
/* eslint-disable prefer-const */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import moment from 'moment';
// COMPONENTS
import { CirclePicker } from 'react-color';
// ICONS
import { GrTextAlignLeft } from 'react-icons/gr';
import { FaSquare } from 'react-icons/fa';
import { HiHashtag } from 'react-icons/hi';
import { BiDollar, BiLink } from 'react-icons/bi';
// CUSTOM COMPONENTS
import Section from '../../../../../components/Section';
import ContentBlock from '../../../../../components/ContentBlock';
import CustomBlock from '../../../../../components/CustomBlock';
import FormBlock from '../../../../../components/FormBlock';
import ContentHeader from '../../../../../components/ContentHeader';
import InputBlock from '../../../../../components/InputBlock';
import SelectBlock from '../../../../../components/SelectBlock';
import TextAreaBlock from '../../../../../components/TextAreaBlock';
import CheckboxBlock from '../../../../../components/CheckboxBlock';
import Image from '../../../../../components/Image';
import FileUploadBlock from '../../../../../components/FileUploadBlock';
import Overlay from '../../../../../components/Overlay';
import ModalBlock from '../../../../../components/ModalBlock';
import FixedActionsBar from '../../../../../components/FixedActionsBar';
// ASSETS
// SERVICES AND HELPERS
import * as helper from '../../../../../helpers/helper';
import * as productService from '../../../../../services/inventory/productService';
import * as categoryService from '../../../../../services/inventory/categoryService';
import * as productTypeService from '../../../../../services/inventory/productTypeService';
import * as productImageService from '../../../../../services/inventory/productImageService';
import ProductValidator from '../../../../../helpers/validators/product/ProductValidator';
// REDUX
import * as auth from '../../../../../redux/authRedux';
import * as alert from '../../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../../redux/confirmModalRedux';

const initialProductModel = {
  id: 0,
  categoryId: '',
  productTypeIds: [],
  name: '',
  additionalInformation: '',
  isFeatured: false,
  currentPrice: '',
  previousPrice: '',
  promoCode: '',
  dealStartsIn: '',
  dealExpiresIn: '',
  link: '',
  keywords: '',
  badgeText: '',
  badgeColor: '#000000',
  isActive: true,
  image: null
};

const ProductManagementPage = (props) => {
  const {
    auth,
    showAlert,
    history,
    showConfirmModal,
    hideConfirmModal
  } = props;
  const { productId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState(initialProductModel);
  const [productImages, setProductImages] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    category: null,
    productTypes: null
  });
  const [productTypeList, setProductTypeList] = useState([]);
  const [files, setFiles] = useState([]);
  const [isColorPaletteVisible, setIsColorPaletteVisible] = useState(false);
  const [updateImageId, setUpdateImageId] = useState(-1);
  const iconSize = 22;
  const inputIconColor = 'grey--clr';
  const hashIcon = <HiHashtag size={iconSize} className={inputIconColor} />;
  const dollarIcon = <BiDollar size={iconSize} className={inputIconColor} />;
  const textIcon = <GrTextAlignLeft size={iconSize} className={inputIconColor} />;
  const linkIcon = <BiLink size={iconSize} className={inputIconColor} />;

  useEffect(() => {
    loadOptionsAndPopulateForm();
  }, []);

  const loadOptionsAndPopulateForm = async () => {
    let category = await getCategoryOptions();
    let productTypes = await getProductTypeOptions();

    if (productId > 0) {
      getProduct(category, productTypes);
    }
  };

  const getProduct = (categories, productTypes) => {
    setIsLoading(true);
    productService.getProductById(productId).then((res) => {
      if (!auth.isAdmin) {
        setIsReadOnly(auth.user.id !== res.addedById);
      }
      setProduct(res);
      setProductImages(res.productImages);
      let selectedCategory = categories.find((x) => x.value === res.categoryId);
      let selectedProductTypeIds = res.productTypes.map((x) => x.productTypeId);
      let selectedProductTypes = productTypes.filter((x) => selectedProductTypeIds.includes(x.value));
      formik.setFieldValue('productTypeIds', selectedProductTypeIds);
      setSelectedOption({ category: selectedCategory || null, productTypes: selectedProductTypes || null });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const deleteImage = () => {
    setIsLoading(true);

    productImageService.deleteImage(updateImageId).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      productService.getProductById(productId).then((res) => {
        setProductImages(res.productImages);
      });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setUpdateImageId(-1);
      hideConfirmModal();
      setIsLoading(false);
    });
  };

  const setImageAsPreview = () => {
    setIsLoading(true);

    productImageService.setPreviewImage(updateImageId).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      productService.getProductById(productId).then((res) => {
        setProductImages(res.productImages);
      });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
      setUpdateImageId(-1);
    });
  };

  const getCategoryOptions = async () => await categoryService.getAllCategories(true).then((res) => {
    let categoryOptionsList = res.map((x) => helper.setToOptionModel(x.name, x.id));
    setCategoryList(categoryOptionsList);

    return categoryOptionsList;
  }).catch((ex) => {
    showAlert({ text: ex.message, state: 'error' });
  });

  const getProductTypeOptions = async () => await productTypeService.getAllProductTypes(true).then((res) => {
    let productTypeOptionsList = res.map((x) => helper.setToOptionModel(x.name, x.id));
    setProductTypeList(productTypeOptionsList);

    return productTypeOptionsList;
  }).catch((ex) => {
    showAlert({ text: ex.message, state: 'error' });
  });

  const formik = useFormik({
    initialValues: product,
    validationSchema: ProductValidator,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (productId) {
        updateProduct(values);
      } else {
        createProduct(values);
      }
    },
  });

  const createProduct = (model) => {
    if (files.length === 0) {
      showAlert({ text: 'Please Upload a Product Image', state: 'warning' });
      return;
    }

    setIsLoading(true);
    productService.createProduct(helper.convertJsonToFormData({ ...model, image: files[0].file })).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push(`/admin/management/product-deal/edit/${res.id}`);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const updateProduct = (model) => {
    setIsLoading(true);

    productService.updateProduct(helper.convertJsonToFormData(model)).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push('/admin/management/product-deals');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const uploadImages = () => {
    if (files.length === 0) {
      return;
    }

    setIsLoading(true);
    productImageService.uploadImages(helper.convertJsonToFormData({ productId, files: files.map((x) => x.file) })).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      getProduct(categoryList, productTypeList);
      setIsModalVisible(false);
      setFiles([]);
    }).catch(() => {
      showAlert({ text: 'Please Upload a Product Image', state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth className="mb-140">
          <ContentBlock>
            <FormBlock className="content-container--card-style--with-shadow" onSubmit={formik.submitForm}>
              {/* PRODUCT INFORMATION */}
              <Section hasNoContainer>
                <ContentBlock>
                  <ContentHeader
                    title={`${product.id > 0 ? 'Update Deal Details' : 'New Deal'}`}
                    headerSize="lg"
                  />
                </ContentBlock>

                <ContentBlock>
                  <ContentHeader
                    title="Product Information"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                </ContentBlock>

                <ContentBlock cols={6}>
                  <InputBlock
                    label="Product Name"
                    isRequired
                    errorMessage={formik.errors.name}
                    inputState={isReadOnly ? 'disabled' : `${helper.getInputClasses(formik, 'name')}`}
                    {...formik.getFieldProps('name')}
                  />
                </ContentBlock>

                <ContentBlock cols={3}>
                  <SelectBlock
                    label="Store"
                    placeholder="Select a store"
                    isRequired
                    options={categoryList}
                    errorMessage={formik.errors.categoryId}
                    inputState={isReadOnly ? 'disabled' : `${helper.getInputClasses(formik, 'categoryId')}`}
                    isDisabled={isReadOnly}
                    {...formik.getFieldProps('categoryId')}
                    value={selectedOption.category}
                    onChange={(selectedOpt) => {
                      selectedOpt = selectedOpt === null ? [] : selectedOpt;
                      setSelectedOption({ ...selectedOption, category: [selectedOpt] });
                      formik.setFieldValue('categoryId', selectedOpt.value);
                    }}
                  />
                </ContentBlock>

                <ContentBlock cols={3}>
                  <SelectBlock
                    label="Product Category"
                    placeholder="Select a product category"
                    options={productTypeList}
                    isRequired
                    isMulti
                    closeMenuOnSelect={false}
                    isDisabled={isReadOnly}
                    errorMessage={formik.errors.productTypeIds}
                    inputState={`${helper.getInputClasses(formik, 'productTypeIds')}`}
                    value={selectedOption.productTypes}
                    onChange={(selectedOpt) => {
                      selectedOpt = selectedOpt === null ? [] : selectedOpt;
                      setSelectedOption({ ...selectedOption, productTypes: selectedOpt });
                      formik.setFieldValue('productTypeIds', selectedOpt.map((x) => x.value));
                    }}
                  />
                </ContentBlock>

                <ContentBlock>
                  <InputBlock
                    label="Keywords"
                    errorMessage={formik.errors.keywords}
                    inputState={isReadOnly ? 'disabled' : `${helper.getInputClasses(formik, 'keywords')}`}
                    {...formik.getFieldProps('keywords')}
                  />
                </ContentBlock>

                <ContentBlock cols={3} className="mt-15">
                  <CheckboxBlock
                    label="Is Active?"
                    id="isActive"
                    disabled={isReadOnly}
                    {...formik.getFieldProps('isActive')}
                    isChecked={formik.values.isActive}
                  />
                </ContentBlock>
              </Section>

              {/* PRODUCT IMAGES */}
              <Section hasNoContainer className="mt-60">
                <ContentBlock>
                  <ContentHeader
                    title="Product Images"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                    primaryButtonText={(productId && !isReadOnly) ? 'Add Images' : ''}
                    primaryButtonOnClick={() => {
                      if (isReadOnly) {
                        return;
                      }
                      setIsModalVisible(true);
                    }}
                  />
                </ContentBlock>

                {
                  product.id === 0
                  && (
                    <ContentBlock className="mb-20">
                      <FileUploadBlock
                        labelIdle="Upload product image"
                        onupdatefiles={setFiles}
                      />
                    </ContentBlock>
                  )
                }

                {
                  productImages.map((item, index) => (
                    <ContentBlock
                      key={index}
                      cols={2}
                    >
                      {
                        item.isPreviewImage && (
                          <CheckboxBlock
                            label="Preview Image"
                            id={`isFeatured_${index}`}
                            isChecked={item.isPreviewImage}
                          />
                        )
                      }
                      <Image
                        source={`${process.env.REACT_APP_API_URL}Attachments/${item.filePath}`}
                        containerClassName="pl-15 pr-15 pt-10 pb-30"
                        onClick={() => {
                          if (isReadOnly) {
                            return;
                          }
                          setUpdateImageId(item.id);
                        }}
                        type="button"
                        to={null}
                      />
                    </ContentBlock>
                  ))
                }
              </Section>

              {/* DISCOUNT INFORMATION */}
              <Section hasNoContainer>
                <ContentBlock>
                  <ContentHeader
                    title="Deal Information"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                </ContentBlock>

                <ContentBlock cols={2}>
                  <InputBlock
                    label="Discount Amount"
                    placeholder="0.0 %"
                    value={(formik.values.previousPrice) && `${Math.ceil(((formik.values.previousPrice - formik.values.currentPrice) / formik.values.previousPrice) * 100)}%`}
                    disabled
                    inputState="disabled"
                  />
                </ContentBlock>

                <ContentBlock cols={2}>
                  <InputBlock
                    label="Current Price"
                    placeholder="e.g. 12.99"
                    iconLeft={dollarIcon}
                    isRequired
                    type="number"
                    errorMessage={formik.errors.currentPrice}
                    inputState={isReadOnly ? 'disabled' : `${helper.getInputClasses(formik, 'currentPrice')}`}
                    {...formik.getFieldProps('currentPrice')}
                  />
                </ContentBlock>

                <ContentBlock cols={2}>
                  <InputBlock
                    label="Previous Price"
                    placeholder="e.g. 8.99"
                    iconLeft={dollarIcon}
                    isRequired
                    type="number"
                    errorMessage={formik.errors.previousPrice}
                    inputState={isReadOnly ? 'disabled' : `${helper.getInputClasses(formik, 'previousPrice')}`}
                    {...formik.getFieldProps('previousPrice')}
                  />
                </ContentBlock>

                <ContentBlock cols={2}>
                  <InputBlock
                    label="Promo Code"
                    iconLeft={hashIcon}
                    errorMessage={formik.errors.promoCode}
                    inputState={isReadOnly ? 'disabled' : `${helper.getInputClasses(formik, 'promoCode')}`}
                    {...formik.getFieldProps('promoCode')}
                  />
                </ContentBlock>

                <ContentBlock cols={2}>
                  <InputBlock
                    type="date"
                    label="Deal Starts In"
                    options={{
                      dateFormat: 'm/d/Y H:i',
                      enableTime: true,
                      time_24hr: false,
                      static: true,
                      minuteIncrement: 1
                    }}
                    disabled={isReadOnly}
                    value={formik.getFieldProps('dealStartsIn').value ? moment((formik.getFieldProps('dealStartsIn').value)).format('MM/DD/YYYY HH:mm') : ''}
                    onChange={(date) => {
                      formik.setFieldValue('dealStartsIn', moment(date[0]).format('MM/DD/YYYY HH:mm'));
                    }}
                  />
                </ContentBlock>

                <ContentBlock cols={2}>
                  <InputBlock
                    type="date"
                    label="Deal Expires In"
                    options={{
                      dateFormat: 'm/d/Y H:i',
                      enableTime: true,
                      time_24hr: false,
                      static: true,
                      minuteIncrement: 1
                    }}
                    disabled={isReadOnly}
                    value={formik.getFieldProps('dealExpiresIn').value ? moment((formik.getFieldProps('dealExpiresIn').value)).format('MM/DD/YYYY HH:mm') : ''}
                    onChange={(date) => {
                      formik.setFieldValue('dealExpiresIn', moment(date[0]).format('MM/DD/YYYY HH:mm'));
                    }}
                  />
                </ContentBlock>

                <ContentBlock cols={6}>
                  <InputBlock
                    label="Affiliate Link"
                    iconLeft={linkIcon}
                    isRequired
                    errorMessage={formik.errors.link}
                    inputState={isReadOnly ? 'disabled' : `${helper.getInputClasses(formik, 'link')}`}
                    {...formik.getFieldProps('link')}
                  />
                </ContentBlock>

                <ContentBlock cols={3}>
                  <InputBlock
                    label="Badge Text"
                    placeholder="e.g. Prime"
                    iconLeft={textIcon}
                    errorMessage={formik.errors.badgeText}
                    inputState={isReadOnly ? 'disabled' : `${helper.getInputClasses(formik, 'badgeText')}`}
                    {...formik.getFieldProps('badgeText')}
                  />
                </ContentBlock>

                <ContentBlock cols={3}>
                  <InputBlock
                    label="Badge Color"
                    placeholder="Type or click box"
                    inputState={isReadOnly ? 'disabled' : `${helper.getInputClasses(formik, 'badgeColor')}`}
                    iconRight={<FaSquare size={35} style={{ color: formik.values.badgeColor ? formik.values.badgeColor : '#d0d0d0' }} />}
                    iconRightOnClick={() => setIsColorPaletteVisible(!isColorPaletteVisible)}
                    {...formik.getFieldProps('badgeColor')}
                  />
                  {
                    isColorPaletteVisible
                    && (
                      <CustomBlock className="ml-10 mr-10">
                        <CirclePicker
                          color={formik.values.badgeColor}
                          onChangeComplete={(c) => {
                            formik.setFieldValue('badgeColor', c.hex);
                          }}
                          className="w-100"
                        />
                      </CustomBlock>
                    )
                  }
                </ContentBlock>

                <ContentBlock>
                  <TextAreaBlock
                    label="Additional Information"
                    errorMessage={formik.errors.additionalInformation}
                    inputState={isReadOnly ? 'disabled' : `${helper.getInputClasses(formik, 'additionalInformation')}`}
                    {...formik.getFieldProps('additionalInformation')}
                  />
                </ContentBlock>
              </Section>

              {/* PAGE ACTIONS */}
              {
                !isReadOnly
                && (
                  <FixedActionsBar
                    primaryActionText={productId ? 'Save Changes' : 'Create'}
                    primaryActionColor="primary--bg"
                    primaryActionOnClick={formik.submitForm}
                    isPrimaryActionDisabled={!formik.isValid}
                    secondaryActionText="Cancel"
                    secondaryActionTo="/admin/management/dashboard"
                    optionalActionText={productId ? 'Return to Deals' : ''}
                    optionalActionTo="/admin/management/product-deals"
                  />
                )
              }
            </FormBlock>
          </ContentBlock>
        </Section>

        {/* ADD IMAGES MODAL */}
        <ModalBlock
          hasCloseAction
          centered
          isVisible={isModalVisible}
          size="md"
          contentHeader="Add Product Images"
          primaryModalActionText="Upload Images"
          primaryModalActionColor="primary--bg"
          primaryModalActionOnClick={uploadImages}
          secondaryModalActionText="Cancel"
          secondaryModalActionColor="danger--bg"
          onHide={() => {
            setIsModalVisible(false);
            setFiles([]);
          }}
        >
          <Section hasNoContainer>
            <ContentBlock>
              <FileUploadBlock allowMultiple onupdatefiles={setFiles} />
            </ContentBlock>
          </Section>
        </ModalBlock>

        {/* UPDATE IMAGE MODAL */}
        <ModalBlock
          hasCloseAction
          centered
          isVisible={updateImageId > 0}
          size="md"
          contentHeader="Select an Option"
          contentDescription="Choose to set this image as the preview image or delete image."
          primaryModalActionText="Set as Preview"
          primaryModalActionColor="primary--bg"
          primaryModalActionOnClick={setImageAsPreview}
          secondaryModalActionText="Delete Image"
          secondaryModalActionColor="danger--bg"
          secondaryModalActionOnClick={() => {
            showConfirmModal({
              title: 'Remove Image',
              text: 'Are you sure you want to remove this image from the product?',
              rightBtnText: 'Confirm',
              btnAction: () => {
                deleteImage();
              }
            });
          }}
          onHide={() => {
            setUpdateImageId(-1);
          }}
        />
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...auth.actions, ...alert.actions, ...confirmModal.actions })(ProductManagementPage);