/* eslint-disable no-use-before-define */
/* eslint-disable  no-unneeded-ternary */
/* eslint-disable  max-len */
// DEPENDENCIES
import React, {
  useEffect,
  useState,
  useCallback
} from 'react';
import { Helmet } from 'react-helmet';
import * as _ from 'lodash';
import { useParams } from 'react-router-dom';
// COMPONENTS
// ICONS
import { HiSearch } from 'react-icons/hi';
// CUSTOM COMPONENTS
import Section from '../../../components/Section';
import ContentBlock from '../../../components/ContentBlock';
import CustomBlock from '../../../components/CustomBlock';
import Overlay from '../../../components/Overlay';
import TextBlock from '../../../components/TextBlock';
import InputBlock from '../../../components/InputBlock';
import SelectBlock from '../../../components/SelectBlock';
import Button from '../../../components/Button';
import ProductCard from '../../../components/ProductCard';
// ASSETS
// SERVICES AND HELPERS
import * as homeService from '../../../services/cms/homeService';
import * as helper from '../../../helpers/helper';

const defaultFilter = {
  sortBy: '',
  priceRange: '',
  categories: null,
  productTypes: null,
  featuredSections: null,
  searchTerm: '',
  skip: 0
};

const FilteredDealsPage = (props) => {
  const { cmsInfo, history } = props;
  const { featured, featuredId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [copiedPromoCode, setCopiedPromoCode] = useState(false);
  const [filter, setFilter] = useState({
    ...defaultFilter,
    featuredSections: (featured && featuredId) ? [{ id: featuredId, name: featured }] : null,
  });
  const [sortByOption, setSortByOption] = useState(null);
  const [storeOption, setStoreOption] = useState(null);
  const [categoryOption, setCategoryOption] = useState(null);
  const [priceRangeOption, setPriceRangeOption] = useState(null);
  const [featuredSectionOption, setFeaturedSectionOption] = useState((featured && featuredId) ? [{ value: featuredId, label: featured }] : null);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (history.location.state) {
      const { predefinedFilter } = history.location.state;
      loadOptionsFromParams(predefinedFilter);
      setFilter(predefinedFilter);
      fetchProducts(predefinedFilter, false);
    } else {
      fetchProducts(filter, false);
    }
  }, [history.location.state]);

  const loadKeywordSuggestions = useCallback(_.debounce((value) => {
    fetchProducts({ ...filter, searchTerm: value }, false);
  }, 1000), []);

  const loadOptionsFromParams = (predefinedFilter) => {
    if (predefinedFilter.categories) {
      setStoreOption(
        predefinedFilter.categories
          .map((x) => ({ label: x.name, value: x.id }))
      );
    }
    if (predefinedFilter.productTypes) {
      setCategoryOption(
        predefinedFilter.productTypes
          .map((x) => ({ label: x.name, value: x.id }))
      );
    }
    if (predefinedFilter.featuredSections) {
      setFeaturedSectionOption(
        predefinedFilter.featuredSections
          .map((x) => ({ label: x.name, value: x.id }))
      );
    }
  };

  const fetchProducts = (predefinedFilter, shouldAppend) => {
    setIsLoading(true);
    homeService.searchProduct(predefinedFilter).then((res) => {
      if (shouldAppend) {
        setProducts([...products, ...res]);
        setFilter({
          ...predefinedFilter,
          skip: res.length + predefinedFilter.skip,
          searchTerm: predefinedFilter.searchTerm
        });
      } else {
        setProducts(res);
        setFilter({
          ...predefinedFilter,
          skip: res.length,
          searchTerm: predefinedFilter.searchTerm
        });
      }

      setHasMore(res.length === 100);
    }).catch(() => {
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const resetFilter = () => {
    setSortByOption(null);
    setStoreOption(null);
    setCategoryOption(null);
    setPriceRangeOption(null);
    setFeaturedSectionOption(null);
    setFilter(defaultFilter);
    fetchProducts(defaultFilter, false);
  };

  const prices = [
    { value: '$', label: '$' },
    { value: '$$', label: '$$' },
    { value: '$$$', label: '$$$' },
    { value: '$$$$', label: '$$$$' },
  ];

  const sortOptions = [
    { value: 'DESC_PRICE', label: 'Price: High to Low' },
    { value: 'ASC_PRICE', label: 'Price: Low to High' },
    { value: 'DESC_DISCOUNT', label: 'Discount: High to Low' },
    { value: 'ASC_DISCOUNT', label: 'Discount: Low to High' },
    { value: 'DESC_DATE', label: 'Newest First' },
  ];

  const copyPromoCode = (code) => {
    if (!code) {
      return;
    }
    helper.copyToClipBoard(code);
    setCopiedPromoCode(code);
  };

  const openInNewTab = (link) => {
    window.open(link, '_blank');
  };

  return (
    <>
      <Helmet>
        <title>Bomb Deals</title>
      </Helmet>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <CustomBlock className="content-wrapper">
          <TextBlock
            isPadded
            isUppercase
            type="header"
            text="Latest Deals"
            color="secondary--clr"
          />

          {/* FILTERS BLOCK */}
          <Section hasNoContainer className="content-container--card-style--with-shadow">
            <ContentBlock cols={4}>
              <InputBlock
                // isLoading
                label="Search Deals"
                placeholder="Enter deal name or keywords"
                value={filter.searchTerm}
                iconLeft={<HiSearch className="mid-grey--clr" size={25} />}
                onChange={(e) => {
                  setFilter({ ...filter, searchTerm: e.target.value });
                  loadKeywordSuggestions(e.target.value);
                }}
              />
            </ContentBlock>
            <ContentBlock cols={4}>
              <SelectBlock
                label="Sort By"
                options={sortOptions}
                isClearable
                value={sortByOption}
                onChange={(e) => {
                  setSortByOption(e);
                  setFilter({ ...filter, sortBy: e ? e.value : '' });
                }}
              />
            </ContentBlock>

            <ContentBlock cols={4}>
              <SelectBlock
                isMulti
                label="Featured Sections"
                placeholder="Select featured sections"
                options={cmsInfo.featuredSections.map((x) => ({ label: x.name, value: x.id }))}
                value={featuredSectionOption}
                onChange={(e) => {
                  setFeaturedSectionOption(e);
                  if (e) {
                    setFilter({
                      ...filter,
                      featuredSections: e.map((x) => ({ id: x.value, name: x.label }))
                    });
                  } else {
                    setFilter({ ...filter, featuredSections: [] });
                  }
                }}
              />
            </ContentBlock>

            <ContentBlock cols={4}>
              <SelectBlock
                isMulti
                label="Stores"
                placeholder="Select stores"
                value={storeOption}
                options={cmsInfo.stores.map((x) => ({ label: x.name, value: x.id }))}
                onChange={(e) => {
                  setStoreOption(e);
                  if (e) {
                    setFilter({
                      ...filter,
                      categories: e.map((x) => ({ id: x.value, name: x.label }))
                    });
                  } else {
                    setFilter({ ...filter, categories: [] });
                  }
                }}
              />
            </ContentBlock>

            <ContentBlock cols={4}>
              <SelectBlock
                isMulti
                label="Category"
                placeholder="Select categories"
                options={cmsInfo.categories.map((x) => ({ label: x.name, value: x.id }))}
                value={categoryOption}
                onChange={(e) => {
                  setCategoryOption(e);
                  if (e) {
                    setFilter({
                      ...filter,
                      productTypes: e.map((x) => ({ id: x.value, name: x.label }))
                    });
                  } else {
                    setFilter({ ...filter, productTypes: [] });
                  }
                }}
              />
            </ContentBlock>

            <ContentBlock cols={4}>
              <SelectBlock
                label="Price"
                options={prices}
                isClearable
                value={priceRangeOption}
                placeholder="Select deal price"
                onChange={(e) => {
                  setPriceRangeOption(e);
                  setFilter({ ...filter, priceRange: e ? e.value : '' });
                }}
              />
            </ContentBlock>

            <ContentBlock>
              <CustomBlock className="content-container--actions mt-15">
                <Button
                  text="Clear All"
                  className="grey--bg"
                  onClick={resetFilter}
                />

                <Button
                  text="Filter"
                  className="secondary--bg"
                  onClick={() => fetchProducts({ ...filter, skip: 0 }, false)}
                />
              </CustomBlock>
            </ContentBlock>
          </Section>

          {/* DEALS */}
          <CustomBlock className="listing-container" style={{ overflow: 'auto' }}>
            <CustomBlock className="product-card-container">
              {
                products.map((product) => (
                  <ProductCard
                    hasRoundCorners
                    isCopied={copiedPromoCode === product.promoCode}
                    title={product.name}
                    imageSource={`${process.env.REACT_APP_API_URL}Attachments/${product.filePath}`}
                    badgeText={product.badgeText}
                    badgeBackgroundColor={product.badgeColor}
                    counterText={product.likes}
                    promoCode={product.promoCode && `${copiedPromoCode === product.promoCode ? 'Copied! ' : 'Copy:'} ${product.promoCode}`}
                    countdownText={helper.countdownMessage(product.dealExpiresIn)}
                    currentPrice={`$${product.currentPrice.toFixed(2)}`}
                    previousPrice={`$${product.previousPrice.toFixed(2)}`}
                    discountAmount={`-${(((product.previousPrice - product.currentPrice) / product.previousPrice) * 100).toFixed()}%`}
                    appStoreLink={cmsInfo.appStoreLink}
                    googlePlayStoreLink={cmsInfo.googlePlayStoreLink}
                    primaryActionOnClick={() => {
                      history.push(`/deal/details/${product.id}`);
                    }}
                    secondaryActionOnClick={() => {
                      copyPromoCode(product.promoCode);
                    }}
                    optionalActionOnClick={() => {
                      openInNewTab(product.link);
                    }}
                  />
                ))
              }

              {
                (!isLoading && products.length === 0)
                && (
                  <CustomBlock className="flex-center mt-60">
                    <TextBlock
                      type="header"
                      text="No Results Found"
                      color="primary--clr"
                    />
                  </CustomBlock>
                )
              }
            </CustomBlock>

          </CustomBlock>

          {
            (!isLoading && hasMore)
            && (
              <CustomBlock className="flex-center mt-60 mb-60">
                <Button
                  type="button"
                  text="Load More Items"
                  size="sm"
                  className="secondary--bg"
                  onClick={() => fetchProducts(filter, true)}
                />
              </CustomBlock>
            )
          }
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

export default FilteredDealsPage;