import * as Yup from 'yup';

export default Yup.object().shape({
  id: Yup.number(),
  categoryId: Yup.number().nullable().required('Store is required'),
  productTypeIds: Yup.array().nullable().required('At least one category is required'),
  name: Yup.string().required('Product Name is required').max(500),
  currentPrice: Yup.number('Please enter valid current price').required('Current Price is required'),
  previousPrice: Yup.number('Please enter valid previous price').required('Previous Price is required'),
  additionalInformation: Yup.string().nullable().max(2000),
  link: Yup.string().nullable().required('Link is required').max(3000),
  keywords: Yup.string().nullable().max(2500),
  badgeText: Yup.string().nullable().max(30, 'Please enter upto 30 words.'),
  badgeColor: Yup.string().nullable().max(50),
  isActive: Yup.bool().nullable(),
  promoCode: Yup.string().nullable().max(50)
});