import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'ProductImage/';

const uploadImages = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}UploadImages`, model, constants.REQUIRE_INTERCEPTORS);

const setPreviewImage = async (imageId) => axiosInstance.put(`${ENDPOINTORIGIN}SetPreviewImage?imageId=${imageId}`, {}, constants.REQUIRE_INTERCEPTORS);

const deleteImage = async (imageId) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteImage?imageId=${imageId}`, constants.REQUIRE_INTERCEPTORS);

export {
  uploadImages,
  setPreviewImage,
  deleteImage
};