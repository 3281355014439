import React from 'react';
import {
  FormLabel,
  FormGroup,
  TextField
} from '@material-ui/core';

const DateFilterComponent = (props) => {
  const {
    filterList,
    onChange,
    index,
    column,
    name
  } = props;

  return (
    <div>
      <FormLabel>{name}</FormLabel>
      <FormGroup row>
        <TextField
          id="startDate"
          label="Start Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={filterList[index][0] || ''}
          onChange={(event) => {
            filterList[index][0] = event.target.value;
            onChange(filterList[index], index, column);
          }}
          style={{ width: '45%', marginRight: '5%' }}
        />
        <TextField
          id="endDate"
          label="End Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={filterList[index][1] || ''}
          onChange={(event) => {
            filterList[index][1] = event.target.value;
            onChange(filterList[index], index, column);
          }}
          style={{ width: '45%', marginRight: '5%' }}
        />
      </FormGroup>
    </div>
  );
};

export default DateFilterComponent;