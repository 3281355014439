/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/label-has-associated-control */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
// ICONS
import { IoLogoTiktok } from 'react-icons/io5';
import {
  RiFacebookFill,
  RiInstagramLine,
  RiTwitterFill,
  RiLinkedinFill,
  RiYoutubeLine,
} from 'react-icons/ri';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
// import Button from './Button';
import Image from './Image';
import TextBlock from './TextBlock';
import InputBlock from './InputBlock';
import FormBlock from './FormBlock';
import Section from './Section';
import ModalBlock from './ModalBlock';
// ASSETS
import AppStoreBadge from '../assets/img/app-store-badge.svg';
import GooglePlayBadge from '../assets/img/google-play-badge.svg';
// HELPERS
import * as alertToast from '../redux/alertToastRedux';

const FooterBlock = (props) => {
  const {
    history,
    to,
    imageSource,
    backgroundColor,
    bottomFooterBackgroundColor,
    subheaderColor,
    subheaderClassName,
    companyName,
    copyrightTextColor,
    footerItems,
    iconSize,
    iconAppearance,
    socialIconColor,
    socialIconBackgroundColor,
    appStoreLink,
    googlePlayLink,
    facebookLink,
    instagramLink,
    twitterLink,
    linkedInLink,
    youtubeLink,
    tiktokLink,
    featuredSections,
    // primaryActionOnClick,
    // secondaryActionOnClick,
    hasSocialMediaIconColor,
    hasEmailSignUp,
  } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    console.log('Is modal visible is ', isModalVisible);
    const hasSigned = localStorage.getItem('has_signed');
    if (!hasSigned && location.pathname !== '/email-blast-signup') {
      setTimeout(() => {
        const lastModalOpenDate = new Date(localStorage.getItem('last_modal_date'));
        const currentDate = new Date();
        const diffTime = Math.abs(currentDate - lastModalOpenDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays > 3) {
          setIsModalVisible(true);
        }
      }, 5000);
    }
  }, []);

  const iconAppearanceOptions = {
    square: 'square',
    round: 'round',
    circle: 'circle',
  };

  const navigateToLink = (link) => {
    window.open(link, '_blank');
  };

  const handleCloseModal = () => {
    localStorage.setItem('last_modal_date', new Date());
    setIsModalVisible(false);
  };

  return (
    <>
      <CustomBlock className={`footer-block ${backgroundColor}`}>
        <CustomBlock className="main-footer">
          <CustomBlock className="left-block">
            <Image
              to={to}
              source={`${process.env.REACT_APP_API_URL}Attachments/${imageSource}`}
            />
          </CustomBlock>

          <CustomBlock className="center-block">
            <TextBlock
              isUppercase
              isPadded
              type="subheader"
              text="Deals and More"
              color="primary--clr"
            />

            <CustomBlock className="links">
              <ul>
                {
                  featuredSections.map((item) => (
                    <li>
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push('/deals', {
                            predefinedFilter: {
                              sortBy: '',
                              priceRange: '',
                              categories: null,
                              productTypes: null,
                              featuredSections: [{ id: item.id, name: item.name }],
                              searchTerm: '',
                              skip: 0
                            }
                          });
                        }}
                      >
                        {item.name}
                      </a>
                    </li>
                  ))
                }
                {
                  footerItems.map((item) => (
                    <li>
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(item.to);
                        }}
                      >
                        {item.label}
                      </a>
                    </li>
                  ))
                }
              </ul>
            </CustomBlock>
          </CustomBlock>

          <CustomBlock className="right-block">
            {
              hasEmailSignUp
              && (
                <CustomBlock className={`${appStoreLink || googlePlayLink !== '' ? 'mb-60' : ''}`}>
                  <>
                    <TextBlock
                      isUppercase
                      type="subheader"
                      text="Get the latest deals"
                      color="primary--clr"
                    />
                    <CustomBlock>
                      <FormBlock
                        className="js-cm-form"
                        id="subForm"
                        action="https://www.createsend.com/t/subscribeerror?description="
                        method="post"
                        data-id="5B5E7037DA78A748374AD499497E309ED6F6FD68776C34FA561D340520E3FF91A67E0E0AB26A3BE89FBAD5DAB61508C34514AF9EEA093D37D9CA99C33C13C3B5"
                      >
                        <CustomBlock>
                          <InputBlock
                            placeholder="Enter your email"
                            inputRightButtonText="Sign Up"
                            inputRightButtonBackgroundColor={`lighter-blue--sbg ${iconAppearance === 'circle' ? 'circle' : ''}`}
                            inputRightButtonTextColor="info--clr"
                            inputRightButtonOnClick={() => {
                              document.querySelector('#emailCampaignSubmitBtn').click();
                            }}
                            backgroundColor="white--sbg"
                            autoComplete="Email"
                            aria-label="Email"
                            className="js-cm-email-input qa-input-email circle"
                            id="fieldEmail"
                            maxLength="200"
                            name="cm-btuukkl-btuukkl"
                            required
                            type="email"
                          />

                        </CustomBlock>

                        <button
                          id="emailCampaignSubmitBtn"
                          type="submit"
                          style={{ display: 'none' }}
                        >
                          Subscribe
                        </button>
                      </FormBlock>
                    </CustomBlock>
                  </>
                </CustomBlock>
              )
            }

            {
              (appStoreLink || googlePlayLink)
              && (
                <CustomBlock className="app-download-block">
                  <TextBlock
                    isPadded
                    isUppercase
                    type="subheader"
                    text="Download Our App"
                    color={subheaderColor}
                    className={subheaderClassName}
                  />

                  <CustomBlock className="app-download-badges">
                    {
                      appStoreLink
                      && (
                        <Image
                          source={AppStoreBadge}
                          onClick={() => navigateToLink(appStoreLink)}
                        />
                      )
                    }

                    {
                      googlePlayLink
                      && (
                        <Image
                          source={GooglePlayBadge}
                          onClick={() => navigateToLink(googlePlayLink)}
                        />
                      )
                    }
                  </CustomBlock>
                </CustomBlock>
              )
            }
          </CustomBlock>
        </CustomBlock>

        <CustomBlock className="social-media-block">
          <CustomBlock className="social-icons">
            {
              facebookLink
              && (
                <button
                  onClick={() => navigateToLink(facebookLink)}
                  className={`social-icon ${hasSocialMediaIconColor ? 'facebook--bg' : socialIconBackgroundColor} ${iconAppearance ? iconAppearanceOptions[iconAppearance] : 'square'}`}
                >
                  <RiFacebookFill className={hasSocialMediaIconColor ? 'white--clr' : socialIconColor} size={iconSize} />
                </button>
              )
            }
            {
              instagramLink
              && (
                <button
                  onClick={() => navigateToLink(instagramLink)}
                  className={`social-icon ${hasSocialMediaIconColor ? 'instagram--bg' : socialIconBackgroundColor} ${iconAppearance ? iconAppearanceOptions[iconAppearance] : 'square'}`}
                >
                  <RiInstagramLine className={hasSocialMediaIconColor ? 'white--clr' : socialIconColor} size={iconSize} />
                </button>
              )
            }
            {
              twitterLink
              && (
                <button
                  onClick={() => navigateToLink(twitterLink)}
                  className={`social-icon ${hasSocialMediaIconColor ? 'twitter--bg' : socialIconBackgroundColor} ${iconAppearance ? iconAppearanceOptions[iconAppearance] : 'square'}`}
                >
                  <RiTwitterFill className={hasSocialMediaIconColor ? 'white--clr' : socialIconColor} size={iconSize} />
                </button>
              )
            }
            {
              linkedInLink
              && (
                <button
                  onClick={() => navigateToLink(linkedInLink)}
                  className={`social-icon ${hasSocialMediaIconColor ? 'linkedin--bg' : socialIconBackgroundColor} ${iconAppearance ? iconAppearanceOptions[iconAppearance] : 'square'}`}
                >
                  <RiLinkedinFill className={hasSocialMediaIconColor ? 'white--clr' : socialIconColor} size={iconSize} />
                </button>
              )
            }
            {
              youtubeLink
              && (
                <button
                  onClick={() => navigateToLink(youtubeLink)}
                  className={`social-icon ${hasSocialMediaIconColor ? 'danger--bg' : socialIconBackgroundColor} ${iconAppearance ? iconAppearanceOptions[iconAppearance] : 'square'}`}
                >
                  <RiYoutubeLine className={hasSocialMediaIconColor ? 'white--clr' : socialIconColor} size={iconSize} />
                </button>
              )
            }
            {
              tiktokLink
              && (
                <button
                  onClick={() => navigateToLink(tiktokLink)}
                  className={`social-icon ${hasSocialMediaIconColor ? 'dark--bg' : socialIconBackgroundColor} ${iconAppearance ? iconAppearanceOptions[iconAppearance] : 'square'}`}
                >
                  <IoLogoTiktok className={hasSocialMediaIconColor ? 'white--clr' : socialIconColor} size={iconSize} />
                </button>
              )
            }
          </CustomBlock>
        </CustomBlock>

        <CustomBlock className={`bottom-footer ${bottomFooterBackgroundColor}`}>
          <p className={`copyright ${copyrightTextColor}`}>{`Copyright © ${new Date().getFullYear()} ${companyName}. All Rights Reserved.`}</p>
          <button
            className={`copyright ml-5 ${copyrightTextColor}`}
            onClick={() => {
              navigateToLink('https://primtek.net/');
            }}
          >
            Developed by Primtek.
          </button>
        </CustomBlock>
      </CustomBlock>

      <ModalBlock
        hasCloseAction
        isVisible={isModalVisible}
        size="md"
        primaryModalActionText="Sign Me Up!"
        primaryModalActionOnClick={() => {
          document.querySelector('#emailCampaignSubmitBtn2').click();
        }}
        primaryModalActionColor="primary--bg"
        secondaryModalActionText="Maybe Later"
        secondaryModalActionOnClick={handleCloseModal}
        onHide={() => {
          setIsModalVisible(false);
        }}
      >
        <Section hasNoContainer>
          <CustomBlock>
            <CustomBlock>
              <TextBlock
                type="header"
                text="Sign up to our email blast!"
                color="secondary--clr text-center pb-20"
                size="sm"
              />
              <FormBlock
                className="js-cm-form"
                id="subForm2"
                action="https://www.createsend.com/t/subscribeerror?description="
                method="post"
                data-id="5B5E7037DA78A748374AD499497E309ED6F6FD68776C34FA561D340520E3FF91A67E0E0AB26A3BE89FBAD5DAB61508C34514AF9EEA093D37D9CA99C33C13C3B5"
                onSubmit={() => {
                  localStorage.setItem('has_signed', true);
                }}
              >
                <CustomBlock>
                  <InputBlock
                    placeholder="Enter your email"
                    autoComplete="Email"
                    aria-label="Email"
                    className="js-cm-email-input qa-input-email circle"
                    id="fieldEmail"
                    maxLength="200"
                    name="cm-btuukkl-btuukkl"
                    required
                    type="email"
                  />

                </CustomBlock>
                <button
                  id="emailCampaignSubmitBtn2"
                  type="submit"
                  style={{ display: 'none' }}
                >
                  Subscribe
                </button>
              </FormBlock>
            </CustomBlock>
          </CustomBlock>
        </Section>
      </ModalBlock>
    </>
  );
};

FooterBlock.propTypes = {
  // MAIN PROPS
  to: PropTypes.string,
  backgroundColor: PropTypes.string,
  bottomFooterBackgroundColor: PropTypes.string,
  imageSource: PropTypes.string,

  // OPTIONAL PROPS

  // SUBHEADER PROPS
  subheaderColor: PropTypes.string,
  subheaderClassName: PropTypes.string,

  // APP STORE PROPS
  appStoreLink: PropTypes.string,
  googlePlayLink: PropTypes.string,

  // SOCIAL MEDIA PROPS
  iconSize: PropTypes.number,
  iconAppearance: PropTypes.string.isRequired,
  socialIconBackgroundColor: PropTypes.string,
  socialIconColor: PropTypes.string,
  facebookLink: PropTypes.string,
  instagramLink: PropTypes.string,
  twitterLink: PropTypes.string,
  linkedInLink: PropTypes.string,
  youtubeLink: PropTypes.string,
  tiktokLink: PropTypes.string,

  // BOTTOM FOOTER PROPS
  companyName: PropTypes.string.isRequired,
  copyrightTextColor: PropTypes.string,

  // FUNCTION PROPS
  // primaryActionOnClick: PropTypes.func,
  // secondaryActionOnClick: PropTypes.func,

  // BOOLEAN PROPS
  hasSocialMediaIconColor: PropTypes.bool,
  hasEmailSignUp: PropTypes.bool,

  footerItems: PropTypes.array,
};

FooterBlock.defaultProps = {
  // MAIN PROPS
  to: '#',
  backgroundColor: 'white--sbg',
  bottomFooterBackgroundColor: 'lightest-grey--sbg',
  imageSource: '',

  // OPTIONAL PROPS

  // SUBHEADER PROPS
  subheaderColor: 'secondary--clr',
  subheaderClassName: '',

  // APP STORE PROPS
  appStoreLink: '',
  googlePlayLink: '',

  // SOCIAL MEDIA PROPS
  iconSize: 27,
  socialIconColor: 'white--clr',
  socialIconBackgroundColor: 'secondary--sbg',
  facebookLink: '',
  instagramLink: '',
  twitterLink: '',
  linkedInLink: '',
  youtubeLink: '',
  tiktokLink: '',

  // BOTTOM FOOTER PROPS
  copyrightTextColor: 'primary--clr',

  // FUNCTION PROPS
  // primaryActionOnClick: null,
  // secondaryActionOnClick: null,

  // BOOLEAN PROPS
  hasSocialMediaIconColor: false,
  hasEmailSignUp: false,

  footerItems: [],
};

export default connect(null, { ...alertToast.actions })(FooterBlock);