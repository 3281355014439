/* eslint-disable no-use-before-define */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
// DEPENDENCIES
import React from 'react';
import { connect } from 'react-redux';
// COMPONENTS
// ICONS
import { HiChevronRight } from 'react-icons/hi';
// CUSTOM COMPONENTS
import Section from '../../../../../components/Section';
import ContentBlock from '../../../../../components/ContentBlock';
import CustomBlock from '../../../../../components/CustomBlock';
import ContentHeader from '../../../../../components/ContentHeader';
import ListBlock from '../../../../../components/ListBlock';
// import Image from '../../../../../components/Image';
// SERVICES AND HELPERS
// REDUX
import * as alert from '../../../../../redux/alertToastRedux';
// ASSETS
// import Logo from '../../../../../assets/img/logo.svg';

const DashboardPage = () => {
  const iconSize = 22;
  const iconRight = <HiChevronRight size={iconSize} />;

  return (
    <CustomBlock className="content-container--padded lighter-grey--sbg" style={{ height: '100vh' }}>
      {/* <Section>
        <ContentBlock cols={4}>
          <CustomBlock className="mt-100 mb-40">
            <Image
              source={Logo}
            />
          </CustomBlock>
        </ContentBlock>
      </Section> */}

      <Section>
        <ContentBlock cols={4} className="pl-15 pr-15 pt-15 pb-15">
          <CustomBlock className="content-container--card-style--with-shadow tile">
            <ContentHeader
              title="Bomb Deals & Promo Code"
              subtitle="View Existing Promo Code and Deals"
              subtitleSize="md"
              className="pb-10"
              containerClassName="pb-20"
              headerSize="md"
            />
            <CustomBlock>
              <ListBlock
                title="Click to View All Deals"
                titleColor="primary--clr main-font fw-600"
                backgroundColor="light--sbg roundest"
                to="/admin/management/product-deals"
                iconRight={iconRight}
                iconRightBackgroundColor="light-grey--sbg"
                roundIconRight
              />
            </CustomBlock>
          </CustomBlock>
        </ContentBlock>

        <ContentBlock cols={4} className="pl-15 pr-15 pt-15 pb-15">
          <CustomBlock className="content-container--card-style--with-shadow tile">
            <ContentHeader
              title="Bomb Deals & Promo Code"
              subtitle="Add New Deals & Promo Codes"
              subtitleSize="md"
              className="pb-10"
              containerClassName="pb-20"
              headerSize="md"
            />

            <CustomBlock>
              <ListBlock
                title="Click to Add Deals"
                titleColor="primary--clr main-font fw-600"
                backgroundColor="light--sbg roundest"
                to="/admin/management/product-deal/create"
                iconRight={iconRight}
                iconRightBackgroundColor="light-grey--sbg"
                roundIconRight
              />
            </CustomBlock>
          </CustomBlock>
        </ContentBlock>
      </Section>
    </CustomBlock>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });
export default connect(mapStateFromProps, { ...alert.actions })(DashboardPage);