import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'FeaturedSection/';

const getAllFeaturedSections = async (isActive) => axiosInstance.get(`${ENDPOINTORIGIN}GetAllFeaturedSections?isActive=${isActive}`, constants.REQUIRE_INTERCEPTORS);

const getFeaturedSection = async (id) => axiosInstance.get(`${ENDPOINTORIGIN}GetFeaturedSection?id=${id}`, constants.REQUIRE_INTERCEPTORS);

const createFeaturedSection = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}CreateFeaturedSection`, model, constants.REQUIRE_INTERCEPTORS);

const updateFeaturedSection = async (model) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateFeaturedSection`, model, constants.REQUIRE_INTERCEPTORS);

const sortFeaturedSections = async (sortOrders) => axiosInstance.put(`${ENDPOINTORIGIN}SortFeaturedSections`, sortOrders, constants.REQUIRE_INTERCEPTORS);

const deleteFeaturedSection = async (featuredSectionId) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteFeaturedSection?id=${featuredSectionId}`, constants.REQUIRE_INTERCEPTORS);

const associateProductsToFeaturedSection = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}associateProductsToFeaturedSection`, model, constants.REQUIRE_INTERCEPTORS);

const removeProductsFromFeaturedSection = async (featuredSectionId, productId) => axiosInstance.delete(`${ENDPOINTORIGIN}RemoveProductsFromFeaturedSection?featuredSectionId=${featuredSectionId}&productId=${productId}`, constants.REQUIRE_INTERCEPTORS);

export {
  getAllFeaturedSections,
  getFeaturedSection,
  createFeaturedSection,
  updateFeaturedSection,
  sortFeaturedSections,
  deleteFeaturedSection,
  associateProductsToFeaturedSection,
  removeProductsFromFeaturedSection
};