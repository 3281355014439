/* eslint-disable no-use-before-define */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
import {
  DragDropContext,
  Droppable,
  Draggable
} from 'react-beautiful-dnd';
// ICONS
import { AiFillHeart } from 'react-icons/ai';
// CUSTOM COMPONENTS
import Overlay from '../../../../../components/Overlay';
import Section from '../../../../../components/Section';
import ContentBlock from '../../../../../components/ContentBlock';
import CustomBlock from '../../../../../components/CustomBlock';
import ContentHeader from '../../../../../components/ContentHeader';
import ModalBlock from '../../../../../components/ModalBlock';
import Button from '../../../../../components/Button';
// SERVICES AND HELPERS
import * as featuredSectionService from '../../../../../services/management/featuredSectionService';
import * as helper from '../../../../../helpers/helper';
// REDUX
import * as alert from '../../../../../redux/alertToastRedux';

const FeaturedSectionListingPage = (props) => {
  const { auth, showAlert } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isSortModalOpen, setIsSortModalOpen] = useState(false);
  const [sortList, setSortList] = useState([]);
  const [featuredSections, setFeaturedSections] = useState([]);
  const [sectionToDeleteId, setSectionToDeleteId] = useState(-1);
  const iconSize = 22;
  const iconColor = 'white--clr';
  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true
  };

  const options = {
    selectableRows: 'none',
    download: true,
    print: false,
    jumpToPage: true,
    textLabels: { pagination: { jumpToPage: 'Page No:' } }
  };

  useEffect(() => {
    getFeaturedSections();
  }, []);

  useEffect(() => {
    setSortList(featuredSections.sort((a, b) => a - b));
  }, [featuredSections]);

  const getFeaturedSections = () => {
    setIsLoading(true);
    featuredSectionService.getAllFeaturedSections('').then((res) => {
      setFeaturedSections(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const deleteFeaturedSection = () => {
    featuredSectionService.deleteFeaturedSection(sectionToDeleteId).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      getFeaturedSections();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setSectionToDeleteId(-1);
    });
  };

  const sortFeaturedSection = () => {
    setIsLoading(true);

    featuredSectionService.sortFeaturedSections(sortList).then((res) => {
      getFeaturedSections();
      showAlert({ text: res.message, state: 'success' });
      setIsSortModalOpen(false);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const tempArr = helper.reOrderDnd(
      sortList,
      result.source.index,
      result.destination.index
    );

    setSortList(tempArr);
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="View"
              className="primary--bg ml-0"
              size="xxs"
              to={`/admin/management/featured-section/edit/${featuredSections[dataIndex].id}`}
            />
            {
              auth.isAdmin && (
                <Button
                  text="Remove"
                  className="danger--bg"
                  size="xxs"
                  onClick={() => {
                    setSectionToDeleteId(featuredSections[dataIndex].id);
                  }}
                />
              )
            }

          </CustomBlock>
        )
      }
    },
    {
      name: 'name',
      label: 'Featured Section Name',
      options: columnOptions,
    },
    {
      name: 'dateCreated',
      label: 'Created On',
      options: columnOptions,
    },
    {
      name: 'dateUpdated',
      label: 'Last Updated',
      options: columnOptions,
    },
    {
      name: 'isActive',
      label: 'Is Active?',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => <p>{featuredSections[dataIndex].isActive ? 'Yes' : 'No'}</p>
      },
    },
  ];

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Featured Sections"
                headerSize="lg"
                primaryButtonText={auth.isAdmin ? 'New Featured Section' : ''}
                primaryButtonIconLeft={<AiFillHeart className={iconColor} size={iconSize} />}
                primaryButtonTo="/admin/management/featured-section"
                secondaryButtonText={auth.isAdmin ? 'Sort Sections' : ''}
                secondaryButtonOnClick={() => {
                  setIsSortModalOpen(true);
                }}
              />

              <CustomBlock>
                <MUIDataTable
                  data={featuredSections}
                  columns={columns}
                  options={options}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      {/* SORT MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={isSortModalOpen}
        size="md"
        contentHeader="Sort Sections"
        primaryModalActionText="Sort"
        primaryModalActionOnClick={sortFeaturedSection}
        onHide={() => {
          setIsSortModalOpen(false);
        }}
      >
        <Section hasNoContainer>
          {
            sortList.length > 0
              ? (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(droppableProvided) => (
                      <div
                        ref={droppableProvided.innerRef}
                      >
                        {sortList.map((item, index) => (
                          <Draggable
                            key={index} draggableId={`${index}`}
                            index={index}
                          >
                            {(draggableProvided) => (
                              <div
                                key={index}
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                                className="content-container--card-style light-grey--sbg pt-15 pb-15 pl-20 pr-20 mb-15"
                              >
                                <p className="main-font fw-500">{item.name}</p>
                              </div>

                            )}
                          </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <CustomBlock className="content-container--card-style light-grey--sbg pt-15 pb-15 pl-20 pr-20 mb-15">
                  <p className="main-font fw-500">No Items Available</p>
                </CustomBlock>
              )
          }
        </Section>
      </ModalBlock>

      {/* CONFIRM DELETE MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={sectionToDeleteId > 0}
        size="md"
        contentHeader="Remove Section"
        contentDescription="Are you sure you want to delete this featured section?"
        primaryModalActionText="Remove"
        primaryModalActionColor="danger--bg"
        primaryModalActionOnClick={deleteFeaturedSection}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="grey--bg"
        onHide={() => {
          setSectionToDeleteId(-1);
        }}
      />
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });
export default connect(mapStateFromProps, { ...alert.actions })(FeaturedSectionListingPage);