/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { createFilter } from 'react-select';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// ICONS
import { BsTagsFill } from 'react-icons/bs';
// CUSTOM COMPONENTS
import Section from '../../../../../components/Section';
import ContentBlock from '../../../../../components/ContentBlock';
import CustomBlock from '../../../../../components/CustomBlock';
import FormBlock from '../../../../../components/FormBlock';
import ContentHeader from '../../../../../components/ContentHeader';
import InputBlock from '../../../../../components/InputBlock';
import FileUploadBlock from '../../../../../components/FileUploadBlock';
import SelectBlock from '../../../../../components/SelectBlock';
import CheckboxBlock from '../../../../../components/CheckboxBlock';
import Image from '../../../../../components/Image';
import Button from '../../../../../components/Button';
import Overlay from '../../../../../components/Overlay';
import ModalBlock from '../../../../../components/ModalBlock';
import FixedActionsBar from '../../../../../components/FixedActionsBar';
// ASSETS
// SERVICES AND HELPERS
import * as helper from '../../../../../helpers/helper';
import * as featuredSectionService from '../../../../../services/management/featuredSectionService';
import * as productService from '../../../../../services/inventory/productService';
import FeaturedSectionValidator from '../../../../../helpers/validators/featuredSection/featuredSectionValidator';
// REDUX
import * as auth from '../../../../../redux/authRedux';
import * as alert from '../../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../../redux/confirmModalRedux';

const initialFeaturedSectionModel = {
  id: 0,
  name: '',
  image: null,
  imageFilePath: '',
  isActive: true,
  sortOrder: 0,
  products: [],
};

const FeaturedSectionManagementPage = (props) => {
  const { showAlert, history, showConfirmModal, hideConfirmModal } = props;
  const { featuredSectionId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [featuredSectionModel, setFeaturedSectionModel] = useState(initialFeaturedSectionModel);
  // const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [file, setFile] = useState([]);
  const iconSize = 22;
  const iconColor = 'white--clr';

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true
  };

  const options = {
    selectableRows: 'none',
    download: true,
    print: false,
  };

  useEffect(() => {
    if (featuredSectionId) {
      getFeaturedSection();
    }

    // getProducts();
  }, []);

  // const getProducts = () => {
  //   productService.getAllProducts(true).then((res) => {
  //     setProducts(res);
  //   }).catch((ex) => {
  //     showAlert({ text: ex.message, state: 'error' });
  //   }).finally(() => {
  //   });
  // };

  const formik = useFormik({
    initialValues: featuredSectionModel,
    validationSchema: FeaturedSectionValidator,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (values.id === 0) {
        createFeaturedSection(values);
      } else {
        updateFeaturedSection(values);
      }
    },
  });

  const getFeaturedSection = () => {
    setIsLoading(true);
    featuredSectionService.getFeaturedSection(featuredSectionId).then((res) => {
      setFeaturedSectionModel(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const createFeaturedSection = (model) => {
    if (file.length === 0) {
      showAlert({ text: 'Please upload an Image', state: 'error' });
      return;
    }
    setIsLoading(true);
    featuredSectionService.createFeaturedSection(helper.convertJsonToFormData(
      { ...model, image: file[0].file }
    )).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push(`/admin/management/featured-section/edit/${res.id}`);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const updateFeaturedSection = (model) => {
    setIsLoading(true);

    if (file.length > 0) {
      model = { ...model, image: file[0].file };
    }
    featuredSectionService.updateFeaturedSection(helper.convertJsonToFormData(model))
      .then((res) => {
        showAlert({ text: res.message, state: 'success' });
        history.push('/admin/management/featured-sections');
      }).catch((ex) => {
        showAlert({ text: ex.message, state: 'error' });
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const saveProductsToFeaturedSection = () => {
    if (selectedProducts.length === 0) {
      showAlert({ text: 'Please select at least one product', state: 'warning' });
      return;
    }

    featuredSectionService.associateProductsToFeaturedSection(
      helper.convertJsonToFormData(
        {
          featuredSectionId,
          productIds: selectedProducts.map((x) => (x.value))
        }
      )
    ).then((res) => {
      getFeaturedSection();
      handleAssociationModalVisibleOnClose();
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleAssociationModalVisibleOnClose = () => {
    setSelectedProducts([]);
    setIsModalVisible(false);
  };

  const removeFromFeaturedSection = (productId) => {
    setIsLoading(true);

    featuredSectionService.removeProductsFromFeaturedSection(featuredSectionId, productId)
      .then((res) => {
        showAlert({ text: res.message, state: 'success' });
        getFeaturedSection();
      }).catch((ex) => {
        showAlert({ text: ex.message, state: 'error' });
      }).finally(() => {
        hideConfirmModal();
        setIsLoading(false);
      });
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="View"
              className="info--bg ml-0"
              size="xxs"
              onClick={() => {
                history.push(`/admin/management/product-deal/edit/${featuredSectionModel.products[dataIndex].id}`);
              }}
            />

            <Button
              text="Remove"
              className="danger--bg"
              size="xxs"
              onClick={() => {
                showConfirmModal({
                  title: 'Remove Product',
                  text: 'Are you sure you want to remove this deal from this featured section?',
                  rightBtnText: 'Confirm',
                  btnAction: () => {
                    removeFromFeaturedSection(featuredSectionModel.products[dataIndex].id);
                  }
                });
              }}
            />
          </CustomBlock>
        )
      }
    },
    {
      name: 'filePath',
      label: 'Preview Image',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <CustomBlock className="small-image-container">
              <Image
                source={`${process.env.REACT_APP_API_URL}Attachments/${featuredSectionModel.products[dataIndex].filePath}`}
              />
            </CustomBlock>
          </CustomBlock>
        )
      }
    },
    {
      name: 'name',
      label: 'Product Name',
      options: columnOptions,
    },
    {
      name: 'category',
      label: 'Category',
      options: columnOptions
    },
    {
      name: 'productTypes',
      label: 'Product Type',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => (
          <p>{featuredSectionModel.products[dataIndex].productTypes.map((x) => x.name).join(', ')}</p>
        )
      }
    },
    {
      name: 'promoCode',
      label: 'Promo Code',
      options: columnOptions
    },
  ];

  const loadOptions = async (keyword) => {
    if (keyword.length > 2) {
      return productService.getProductBySearchTerm(keyword).then((res) => (
        res.filter(
          (product) => !featuredSectionModel.products.map((x) => x.id)
            .includes(product.id)
        )
          .map((x) => (
            {
              label: x.name,
              value: x.id,
              image: `${process.env.REACT_APP_API_URL}Attachments/${x.imageUrl}`
            }))
      ));
    }

    return [];
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <FormBlock className="content-container--card-style--with-shadow" onSubmit={formik.submitForm}>
              {/* PRODUCT INFORMATION */}
              <Section hasNoContainer>
                <ContentBlock>
                  <ContentHeader
                    title="New Featured Section"
                    headerSize="lg"
                  />

                </ContentBlock>
                <ContentBlock>
                  <ContentHeader
                    title="Basic Information"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                </ContentBlock>

                <ContentBlock>
                  <ContentBlock cols={3}>
                    <InputBlock
                      label="Featured Section Name"
                      errorMessage={formik.errors.name}
                      inputState={`${helper.getInputClasses(formik, 'name')}`}
                      {...formik.getFieldProps('name')}
                      isRequired
                    />
                  </ContentBlock>
                </ContentBlock>

                <ContentBlock>
                  <ContentBlock cols={3}>
                    {

                      featuredSectionModel.id === 0
                        ? <FileUploadBlock onupdatefiles={setFile} />
                        : (
                          <>
                            <CustomBlock className="flex-center pt-15 pb-30">
                              <CustomBlock className="medium-image-container">
                                <Image
                                  source={`${process.env.REACT_APP_API_URL}Attachments/${featuredSectionModel.imageFilePath}`}
                                />
                              </CustomBlock>
                            </CustomBlock>
                            <FileUploadBlock onupdatefiles={setFile} labelIdle="Replace image" />
                          </>
                        )
                    }
                  </ContentBlock>
                </ContentBlock>

                <ContentBlock className="mt-15">
                  <CheckboxBlock
                    label="Is Featured Section Active?"
                    id="isActive"
                    {...formik.getFieldProps('isActive')}
                    isChecked={formik.values.isActive}
                  />
                </ContentBlock>
              </Section>

              {
                featuredSectionModel.id > 0
                && (
                  <Section hasNoContainer className="mt-60">
                    <ContentBlock>
                      <ContentHeader
                        title="Deals and Promo Codes"
                        headerSize="lg"
                        primaryButtonText="Add Deals"
                        primaryButtonIconLeft={<BsTagsFill className={iconColor} size={iconSize} />}
                        primaryButtonOnClick={() => setIsModalVisible(true)}
                      />
                    </ContentBlock>

                    <ContentBlock>
                      <MUIDataTable
                        data={featuredSectionModel.products}
                        columns={columns}
                        options={options}
                      />
                    </ContentBlock>
                  </Section>
                )
              }

              {/* PAGE ACTIONS */}
              <FixedActionsBar
                primaryActionText={featuredSectionModel.id === 0 ? 'Create' : 'Save Changes'}
                primaryActionColor="primary--bg"
                primaryActionOnClick={formik.submitForm}
                secondaryActionText="Cancel"
                secondaryActionTo="/admin/management/featured-sections"
                optionalActionText={featuredSectionModel.id > 0 ? 'Return to Listing' : ''}
                optionalActionTo="/admin/management/featured-sections"
              />
            </FormBlock>
          </ContentBlock>
        </Section>

        {/* ADD IMAGES MODAL */}
        <ModalBlock
          hasCloseAction
          centered
          isVisible={isModalVisible}
          size="lg"
          contentHeader="Add Deals"
          primaryModalActionText="Add"
          primaryModalActionColor="primary--bg"
          primaryModalActionOnClick={saveProductsToFeaturedSection}
          secondaryModalActionText="Cancel"
          secondaryModalActionColor="danger--bg"
          onHide={() => {
            setIsModalVisible(false);
          }}
        >
          <Section hasNoContainer>
            <ContentBlock>
              <SelectBlock
                isAsync
                label="Deals and Promo Codes"
                placeholder="Enter deals and promo codes to search"
                closeMenuOnSelect
                filterOption={createFilter({ ignoreAccents: false })}
                loadOptions={loadOptions}
                // options={
                //   products.filter(
                //     (product) => !featuredSectionModel.products.map((x) => x.id)
                //       .includes(product.id)
                //   )
                //     .map((x) => (
                //       {
                //         label: x.name,
                //         value: x.id,
                //         // image: `${process.env.REACT_APP_API_URL}Attachments/${x.imageUrl}`
                //       }))
                // }
                formatOptionLabel={(option) => (
                  <CustomBlock className="content-container--actions flex-start pl-0 mt-0 mb-10">
                    <CustomBlock className="smaller-image-container">
                      <Image
                        source={option.image}
                      />
                    </CustomBlock>
                    <p className="ml-15 fw-400">{option.label}</p>
                  </CustomBlock>
                )}
                isMulti
                value={selectedProducts}
                onChange={(selectedOpt) => {
                  selectedOpt = selectedOpt === null ? [] : selectedOpt;
                  setSelectedProducts(selectedOpt);
                }}
              />
            </ContentBlock>
          </Section>
        </ModalBlock>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...auth.actions,
    ...confirmModal.actions,
    ...alert.actions
  }
)(FeaturedSectionManagementPage);