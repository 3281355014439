/* eslint-disable max-len */
/* eslint-disable react/self-closing-comp */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
// COMPONENTS
import Slider from 'react-slick';
// ICONS
import { FaChevronRight } from 'react-icons/fa';
// CUSTOM COMPONENTS
// import Section from '../../../components/Section';
// import ContentBlock from '../../../components/ContentBlock';
import CustomBlock from '../../../components/CustomBlock';
import Overlay from '../../../components/Overlay';
import TextBlock from '../../../components/TextBlock';
import ProductCard from '../../../components/ProductCard';
import ImageCard from '../../../components/ImageCard';
import Image from '../../../components/Image';
import Banner from '../../../components/Banner';
import ContentStrip from '../../../components/ContentStrip';
// ASSETS - APP LINKS
import AppStoreBadge from '../../../assets/img/app-store-badge.svg';
import GooglePlayBadge from '../../../assets/img/google-play-badge.svg';
// HELPERS
import * as helper from '../../../helpers/helper';
// import constants from '../../../constants/constants';
// SERVICES
import * as productService from '../../../services/inventory/productService';

const HomePage = (props) => {
  const { dynamicPageModel, buttonAction, cmsInfo, history } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [banners, setBanners] = useState([]);
  const [sections, setSections] = useState([]);
  const [copiedPromoCode, setCopiedPromoCode] = useState(false);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  useEffect(() => {
    const rotatingBanners = dynamicPageModel.pageContentSectionBanners.find((x) => x.bannerId > 0);
    if (rotatingBanners) {
      setBanners(rotatingBanners.banner.bannerItems);
    }
    getSections();
  }, []);

  const copyPromoCode = (code) => {
    if (!code) {
      return;
    }
    helper.copyToClipBoard(code);
    setCopiedPromoCode(code);
  };

  const getSections = () => {
    setIsLoading(true);

    productService.getProductsForDashboard('').then((res) => {
      setSections(res.sections);
    }).catch().finally(() => {
      setIsLoading(false);
    });
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,

    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: 'unslick'
      }
    ]
  };

  const storesSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 3,
    arrows: false,

    responsive: [
      {
        breakpoint: 1800,
        settings: { slidesToShow: 5 }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplay: true,
          autoplaySpeed: 3000,
          dots: false,
        }
      }
    ]
  };

  const bannerSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,

    responsive: [
      {
        breakpoint: 480,
        settings: { dots: false }
      }
    ]
  };

  const navigateToLink = (link) => {
    window.open(link, '_blank');
  };

  const openInNewTab = (link) => {
    window.open(link, '_blank');
  };

  return (
    <>
      {(isLoading) && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <CustomBlock className="content-wrapper with-side-bar">

          <CustomBlock className="deal-listing-block">
            <CustomBlock className="banner-container">
              <Slider {...bannerSliderSettings}>
                {
                  banners.map((banner) => (
                    <Banner
                      isDynamicBanner
                      isTitleUppercase
                      isCentered
                      // isReversed={banner.alignment === constants.CONTENT_SECTION.RightAlignment}
                      // isCentered={banner.alignment === constants.CONTENT_SECTION.CenterAlignment}
                      title={banner.title}
                      imageSource={`${process.env.REACT_APP_API_URL}Attachments${banner.imagePath}`}
                      description={banner.description}
                      primaryActionText={banner.primaryButtonText}
                      primaryActionOnClick={
                        () => buttonAction(banner.primaryButtonActionType, banner.primaryButtonLink)
                      }
                      secondaryActionText={banner.secondaryButtonText}
                      secondaryActionOnClick={
                        () => buttonAction(banner.secondaryButtonActionType, banner.secondaryButtonLink)
                      }
                    />
                  ))
                }
              </Slider>
            </CustomBlock>

            {
              sections.map((section, index) => (
                <CustomBlock
                  key={section.id}
                  className="product-carousel pt-60 mb-60"
                >
                  <CustomBlock>
                    <TextBlock
                      hasAction
                      isUppercase
                      type="header"
                      text={section.name}
                      color="secondary--clr"
                      actionText="View All"
                      actionSize="md"
                      actionIconRight={<FaChevronRight size={20} className="ml-10" />}
                      onClick={() => {
                        // history.push('/deals', {
                        //   predefinedFilter: {
                        //     sortBy: '',
                        //     priceRange: '',
                        //     categories: null,
                        //     searchTerm: '',
                        //     productTypes: null,
                        //     featuredSections: (index !== sections.length - 1) ? [{ id: section.id, name: section.name }] : null,
                        //     skip: 0
                        //   }
                        // });
                        if (index !== sections.length - 1) {
                          history.push(`/deals/${section.name}/${section.id}`);
                        } else {
                          history.push('/deals', {
                            predefinedFilter: {
                              sortBy: '',
                              priceRange: '',
                              categories: null,
                              searchTerm: '',
                              productTypes: null,
                              featuredSections: (index !== sections.length - 1) ? [{ id: section.id, name: section.name }] : null,
                              skip: 0
                            }
                          });
                        }
                      }}
                    />
                  </CustomBlock>

                  <CustomBlock>
                    <Slider {...settings}>
                      {
                        section.products.map((product) => (
                          <ProductCard
                            hasRoundCorners
                            isCopied={copiedPromoCode === product.promoCode}
                            title={product.name}
                            imageSource={`${process.env.REACT_APP_API_URL}Attachments/${product.filePath}`}
                            badgeText={product.badgeText}
                            badgeBackgroundColor={product.badgeColor}
                            counterText={product.likes}
                            promoCode={product.promoCode && `${copiedPromoCode === product.promoCode ? 'Copied! ' : 'Copy:'} ${product.promoCode}`}
                            countdownText={helper.countdownMessage(product.dealExpiresIn)}
                            currentPrice={`$${product.currentPrice.toFixed(2)}`}
                            previousPrice={`$${product.previousPrice.toFixed(2)}`}
                            discountAmount={`-${(((product.previousPrice - product.currentPrice) / product.previousPrice) * 100).toFixed()}%`}
                            appStoreLink={cmsInfo.appStoreLink}
                            googlePlayStoreLink={cmsInfo.googlePlayStoreLink}
                            primaryActionOnClick={() => {
                              history.push(`/deal/details/${product.id}`);
                            }}
                            secondaryActionOnClick={() => {
                              copyPromoCode(product.promoCode);
                            }}
                            optionalActionOnClick={() => {
                              openInNewTab(product.link);
                            }}
                          />
                        ))
                      }
                    </Slider>
                  </CustomBlock>
                </CustomBlock>
              ))
            }
            {/* FEATURED DEALS */}

            {/* CTA */}
            <CustomBlock hasNoContainer className="pt-60 mb-60">
              <CustomBlock className="cta-block">
                <TextBlock
                  isUppercase
                  type="header"
                  text="Work Hard. Shop Harder."
                  color="secondary--clr"
                />

                <p className="signature">- The Bomb Squad</p>
              </CustomBlock>

              <CustomBlock className="mt-30 image-card-carousel">
                <Slider {...storesSliderSettings}>
                  {
                    cmsInfo.stores.map((item) => (
                      <ImageCard
                        imageSource={`${process.env.REACT_APP_API_URL}Attachments/${item.imageFilePath}`}
                        title={item.name}
                        cardAppearance="round"
                        containerClassName="carousel-images"
                        onClick={() => {
                          history.push('/deals', {
                            predefinedFilter: {
                              sortBy: '',
                              priceRange: '',
                              categories: [{ id: item.id, name: item.name }],
                              searchTerm: '',
                              productTypes: null,
                              featuredSections: null,
                              skip: 0
                            }
                          });
                        }}
                      />
                    ))
                  }
                </Slider>
              </CustomBlock>
            </CustomBlock>
          </CustomBlock>

          <CustomBlock className="side-items-block">
            <CustomBlock className="app-download-block">
              <TextBlock
                isPadded
                isUppercase
                type="header"
                text="Download Our App"
                position="center"
                color="primary--clr"
              />

              <CustomBlock className="app-badges">
                <Image
                  source={AppStoreBadge}
                  onClick={() => navigateToLink(cmsInfo.appStoreLink)}
                  className="ml-10 mr-10"
                />
                <Image
                  source={GooglePlayBadge}
                  onClick={() => navigateToLink(cmsInfo.googlePlayStoreLink)}
                  className="ml-10 mr-10"
                />
              </CustomBlock>
            </CustomBlock>

            <CustomBlock className="blog-side-strip">
              <TextBlock
                isPadded
                isUppercase
                type="header"
                text="Blogs"
                position="center"
                color="primary--clr"
              />

              <CustomBlock>
                {
                  cmsInfo.pages.filter((x) => x.isBlog).slice(0, 4).map((item) => (
                    <ContentStrip
                      isFullWidth
                      imageSource={`${process.env.REACT_APP_API_URL}Attachments/${item.bannerImageFilePath}`}
                      title={item.name}
                      leftBlockText={item.addedOn}
                      rightBlockText={`By: ${item.addedBy}`}
                      badgeText={new Date(item.addedOn).getTime() === today.getTime() ? 'New' : ''}
                      onClick={() => {
                        history.push(item.url);
                      }}
                    />
                  ))
                }
                {
                  cmsInfo.pages.filter((x) => x.isBlog).length === 0
                  && (
                    <CustomBlock className="flex-center mt-20">
                      <TextBlock
                        type="header"
                        text="No Blogs Available"
                        color="secondary--clr size-mdlg pb-40"
                        position="center"
                      />
                    </CustomBlock>
                  )
                }
              </CustomBlock>
            </CustomBlock>
          </CustomBlock>
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

export default HomePage;