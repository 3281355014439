import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'PushNotificationHistory/';

const getPushNotificationHistory = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetPushNotificationHistory`, constants.REQUIRE_INTERCEPTORS);

const sendPushNotifications = async (title, message) => axiosInstance.post(`${ENDPOINTORIGIN}SendPushNotifications?title=${title}&message=${message}`, {}, constants.REQUIRE_INTERCEPTORS);

export {
  getPushNotificationHistory,
  sendPushNotifications
};