/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable react/no-array-index-key */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
import {
  DragDropContext,
  Droppable,
  Draggable
} from 'react-beautiful-dnd';
// ICONS
import { IoListSharp } from 'react-icons/io5';
// CUSTOM COMPONENTS
import Section from '../../../../../components/Section';
import ContentBlock from '../../../../../components/ContentBlock';
import CustomBlock from '../../../../../components/CustomBlock';
import FormBlock from '../../../../../components/FormBlock';
import ContentHeader from '../../../../../components/ContentHeader';
import Button from '../../../../../components/Button';
import ModalBlock from '../../../../../components/ModalBlock';
import InputBlock from '../../../../../components/InputBlock';
import FileUploadBlock from '../../../../../components/FileUploadBlock';
import Image from '../../../../../components/Image';
import CheckboxBlock from '../../../../../components/CheckboxBlock';
import Overlay from '../../../../../components/Overlay';
// ASSETS
// SERVICES AND HELPERS
import ProductTypeValidator from '../../../../../helpers/validators/productType/ProductTypeValidator';
import * as helper from '../../../../../helpers/helper';
import * as productTypeService from '../../../../../services/inventory/productTypeService';
// REDUX
import * as auth from '../../../../../redux/authRedux';
import * as alert from '../../../../../redux/alertToastRedux';

const initialProductTypeModel = {
  id: 0,
  name: '',
  logo: null,
  notifyImmediately: false,
  imageFilePath: '',
  isActive: true,
  sortOrder: 0
};

const ProductCategoryListing = (props) => {
  const { showAlert } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConfirmDeleteModalVisible, setIsConfirmDeleteModalVisible] = useState(false);
  const [productTypes, setProductTypes] = useState([]);
  const [productTypeModel, setProductTypeModel] = useState(initialProductTypeModel);
  const [file, setFile] = useState([]);
  const [isSortModalOpen, setIsSortModalOpen] = useState(false);
  const [sortList, setSortList] = useState([]);

  const iconSize = 22;
  const iconColor = 'white--clr';

  useEffect(() => {
    getAllProductTypes();
  }, []);

  useEffect(() => {
    setSortList(productTypes.sort((a, b) => a - b));
  }, [productTypes]);

  const getAllProductTypes = () => {
    setIsLoading(true);
    productTypeService.getAllProductTypes('').then((res) => {
      setProductTypes(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const formik = useFormik({
    initialValues: productTypeModel,
    validationSchema: ProductTypeValidator,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (values.id === 0) {
        createProductType(values);
      } else {
        updateProductType(values);
      }
    },
  });

  const getProductType = (id) => {
    setIsLoading(true);
    productTypeService.getProductTypesById(id).then((res) => {
      setProductTypeModel(res);
      setIsModalVisible(true);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const createProductType = (model) => {
    if (file.length === 0) {
      showAlert({ text: 'Please upload an Image', state: 'error' });
      return;
    }
    setIsLoading(true);
    productTypeService.createProductType(helper.convertJsonToFormData({ ...model, logo: file[0].file })).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      getAllProductTypes();
      closeProductTypeModal();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const updateProductType = (model) => {
    setIsLoading(true);

    if (file.length > 0) {
      model = { ...model, logo: file[0].file };
    }
    productTypeService.updateProductTypes(helper.convertJsonToFormData(model)).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      getAllProductTypes();
      closeProductTypeModal();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const closeProductTypeModal = () => {
    setIsModalVisible(false);
    setTimeout(() => {
      formik.resetForm();
      setProductTypeModel(initialProductTypeModel);
      setFile([]);
    }, 250);
  };

  const sortProductTypes = () => {
    setIsLoading(true);

    productTypeService.sortProductTypes(sortList).then((res) => {
      getAllProductTypes();
      showAlert({ text: res.message, state: 'success' });
      setIsSortModalOpen(false);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const tempArr = helper.reOrderDnd(
      sortList,
      result.source.index,
      result.destination.index
    );

    setSortList(tempArr);
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true,
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="View"
              className="primary--bg ml-0"
              size="xxs"
              onClick={() => {
                getProductType(productTypes[dataIndex].id);
              }}
            />
          </CustomBlock>
        )
      }
    },
    {
      name: 'productTypeImage',
      label: 'Image',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <CustomBlock className="small-image-container">
              <Image
                source={`${process.env.REACT_APP_API_URL}Attachments/${productTypes[dataIndex].imageFilePath}`}
              />
            </CustomBlock>
          </CustomBlock>
        )
      }
    },
    {
      name: 'name',
      label: 'Product Category',
      options: columnOptions,
    },
    {
      name: 'notifyImmediately',
      label: 'Send Notification Immediately?',
      options: {
        filter: true,
        sort: true,
        print: false,
        download: true,
        customBodyRenderLite: (dataIndex) => <p>{productTypes[dataIndex].notifyImmediately ? 'Yes' : 'No'}</p>
      }
    },
    {
      name: 'isActive',
      label: 'Is Active?',
      options: {
        filter: true,
        sort: true,
        print: false,
        download: true,
        customBodyRenderLite: (dataIndex) => <p>{productTypes[dataIndex].isActive ? 'Yes' : 'No'}</p>
      }
    },
  ];

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Product Categories"
                headerSize="lg"
                primaryButtonText="Add New Product Category"
                primaryButtonIconLeft={<IoListSharp className={iconColor} size={iconSize} />}
                primaryButtonOnClick={() => {
                  setIsModalVisible(true);
                }}
                secondaryButtonText="Sort Product Categories"
                secondaryButtonOnClick={() => {
                  setIsSortModalOpen(true);
                }}
              />
              <CustomBlock>
                <MUIDataTable
                  data={productTypes}
                  columns={columns}
                  options={{
                    selectableRows: 'none',
                    download: true,
                    print: false,
                    rowsPerPage: 100,
                    jumpToPage: true,
                    textLabels: { pagination: { jumpToPage: 'Page No:' } }
                  }}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      {/* SORT MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={isSortModalOpen}
        size="md"
        contentHeader="Sort Sections"
        primaryModalActionText="Sort"
        primaryModalActionOnClick={sortProductTypes}
        onHide={() => {
          setIsSortModalOpen(false);
        }}
      >
        <Section hasNoContainer>
          {
            sortList.length > 0
              ? (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(droppableProvided) => (
                      <div
                        ref={droppableProvided.innerRef}
                      >
                        {sortList.map((item, index) => (
                          <Draggable
                            key={index} draggableId={`${index}`}
                            index={index}
                          >
                            {(draggableProvided) => (
                              <div
                                key={index}
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                                className="content-container--card-style light-grey--sbg pt-15 pb-15 pl-20 pr-20 mb-15"
                              >
                                <p className="main-font fw-500">{item.name}</p>
                              </div>

                            )}
                          </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <CustomBlock className="content-container--card-style light-grey--sbg pt-15 pb-15 pl-20 pr-20 mb-15">
                  <p className="main-font fw-500">No Items Available</p>
                </CustomBlock>
              )
          }
        </Section>
      </ModalBlock>

      {/* VIEW/UPDATE MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={isModalVisible}
        size="md"
        contentHeader={productTypeModel.id === 0 ? 'New Category' : 'Update Details'}
        primaryModalActionText={productTypeModel.id === 0 ? 'Add' : 'Update'}
        primaryModalActionOnClick={formik.submitForm}
        onHide={closeProductTypeModal}
      >
        <FormBlock onSubmit={formik.submitForm}>
          <Section hasNoContainer>
            <ContentBlock>
              <InputBlock
                label="Product Category Name"
                errorMessage={formik.errors.name}
                inputState={`${helper.getInputClasses(formik, 'name')}`}
                {...formik.getFieldProps('name')}
                isRequired
              />
            </ContentBlock>

            <ContentBlock>
              {
                productTypeModel.id === 0
                  ? <FileUploadBlock onupdatefiles={setFile} />
                  : (
                    <>
                      <CustomBlock className="flex-center pt-15 pb-30">
                        <CustomBlock className="medium-image-container">
                          <Image
                            source={`${process.env.REACT_APP_API_URL}Attachments/${productTypeModel.imageFilePath}`}
                          />
                        </CustomBlock>
                      </CustomBlock>
                      <FileUploadBlock onupdatefiles={setFile} labelIdle="Replace image" />
                    </>
                  )
              }
            </ContentBlock>

            <ContentBlock>
              <CheckboxBlock
                label="Send notification immediately?"
                id="notifyImmediately"
                {...formik.getFieldProps('notifyImmediately')}
                isChecked={formik.values.notifyImmediately}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <CheckboxBlock
                label="Is Active?"
                id="isActive"
                {...formik.getFieldProps('isActive')}
                isChecked={formik.values.isActive}
              />
            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock>

      {/* CONFIRM DELETE MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={isConfirmDeleteModalVisible}
        size="md"
        contentHeader="Delete Category"
        contentDescription="Are you sure you want to delete this product category?"
        primaryModalActionText="Delete"
        primaryModalActionColor="danger--bg"
        primaryModalActionOnClick={() => { }}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="grey--bg"
        onHide={() => {
          setIsConfirmDeleteModalVisible(false);
        }}
      />
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...auth.actions, ...alert.actions })(ProductCategoryListing);