/* eslint-disable react/self-closing-comp */
// DEPENDENCIES
import React, { useState } from 'react';
// COMPONENTS
// ICONS
import { HiSearch } from 'react-icons/hi';
// CUSTOM COMPONENTS
import Section from '../../../components/Section';
import ContentBlock from '../../../components/ContentBlock';
import CustomBlock from '../../../components/CustomBlock';
import Overlay from '../../../components/Overlay';
import TextBlock from '../../../components/TextBlock';
import InputBlock from '../../../components/InputBlock';
import ImageCard from '../../../components/ImageCard';
// ASSETS

const ProductCategoriesPage = (props) => {
  const { cmsInfo, history } = props;
  const [isLoading] = useState(false);
  const [filteredCategories, setFilteredCategories] = useState(cmsInfo.categories);

  const search = (searchTerm) => {
    setFilteredCategories(cmsInfo.categories
      .filter((x) => x.name.toLowerCase().includes(searchTerm.toLowerCase())));
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <CustomBlock className="content-wrapper">
          <TextBlock
            isPadded
            isUppercase
            type="header"
            text="Stores"
            color="secondary--clr"
          />

          {/* FILTERS BLOCK */}
          <Section hasNoContainer className="content-container--card-style--with-shadow">
            <ContentBlock>
              <CustomBlock className="search-block full-width">
                <InputBlock
                  placeholder="Look up product categories e.g. electronics, beauty, shoes, jewelry..."
                  iconLeft={<HiSearch className="mid-grey--clr" size={25} />}
                  onChange={(e) => search(e.target.value)}
                />
              </CustomBlock>
            </ContentBlock>
          </Section>

          {/* ITEMS */}
          <CustomBlock className="listing-container">
            <CustomBlock className="image-card-list">
              {
                filteredCategories.map((item) => (
                  <ImageCard
                    key={item.id}
                    imageSource={`${process.env.REACT_APP_API_URL}Attachments${item.imageFilePath}`}
                    title={item.name}
                    cardAppearance="round"
                    onClick={() => {
                      history.push('/deals', {
                        predefinedFilter: {
                          sortBy: '',
                          searchTerm: '',
                          priceRange: '',
                          categories: null,
                          productTypes: [{ id: item.id, name: item.name }],
                          featuredSections: null,
                          skip: 0
                        }
                      });
                    }}
                  />
                ))
              }
            </CustomBlock>
          </CustomBlock>
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

export default ProductCategoriesPage;