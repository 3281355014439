// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Image from './Image';
import TextBlock from './TextBlock';

const ImageCard = (props) => {
  const {
    imageSource,
    title,
    cardAppearance,
    className,
    containerClassName,
    onClick
  } = props;

  const cardAppearanceOptions = {
    square: 'square',
    round: 'round',
    circle: 'circle',
  };

  return (
    <CustomBlock className="image-card-container">
      <CustomBlock className={`image-card-block ${cardAppearance ? cardAppearanceOptions[cardAppearance] : 'square'} ${containerClassName}`}>
        <Image
          source={imageSource}
          onClick={onClick}
          containerClassName={`image-card ${className}`}
        />
      </CustomBlock>

      <TextBlock
        type="description"
        text={title}
        position="center"
      />
    </CustomBlock>
  );
};

ImageCard.propTypes = {
  // MAIN PROPS
  imageSource: PropTypes.number,
  title: PropTypes.string,
  cardAppearance: PropTypes.string.isRequired,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  onClick: PropTypes.func,
};

ImageCard.defaultProps = {
  // MAIN PROPS
  imageSource: null,
  title: '',
  className: '',
  containerClassName: '',
  onClick: null,
};

export default ImageCard;