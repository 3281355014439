/* eslint-disable max-len */
// DEPENDENCIES
import React from 'react';
// COMPONENTS
// CUSTOM COMPONENTS
// import Section from '../../../components/Section';
// import ContentBlock from '../../../components/ContentBlock';
import TextBlock from '../../../components/TextBlock';
import CustomBlock from '../../../components/CustomBlock';
import FormBlock from '../../../components/FormBlock';
import InputBlock from '../../../components/InputBlock';

const EmailBlastSignUpPage = () => (
  <CustomBlock className="main-container content-container--padded secondary-lighter--sbg pt-100 pl-20 pr-20">
    <TextBlock
      type="header"
      text="Email Blast"
      color="secondary--clr"
    />
    <TextBlock
      type="description"
      text="Sign up to our email blast and get the latest news about our deals and discounts!"
      color="secondary--clr"
    />
    <FormBlock
      className="js-cm-form"
      id="subForm"
      action="https://www.createsend.com/t/subscribeerror?description="
      method="post"
      data-id="5B5E7037DA78A748374AD499497E309ED6F6FD68776C34FA561D340520E3FF91A67E0E0AB26A3BE89FBAD5DAB61508C34514AF9EEA093D37D9CA99C33C13C3B5"
    >
      <CustomBlock className="footer-block">
        <InputBlock
          placeholder="Enter your email"
          inputRightButtonText="Sign Up"
          inputRightButtonBackgroundColor="lighter-blue--sbg circle"
          inputRightButtonTextColor="info--clr"
          inputRightButtonOnClick={() => {
            document.querySelector('#emailCampaignSubmitBtn').click();
          }}
          backgroundColor="white--sbg"
          autoComplete="Email"
          aria-label="Email"
          className="js-cm-email-input qa-input-email circle"
          id="fieldEmail"
          maxLength="200"
          name="cm-btuukkl-btuukkl"
          required
          type="email"
        />

      </CustomBlock>

      <CustomBlock>
        <button
          id="emailCampaignSubmitBtn"
          type="submit"
          style={{ display: 'none' }}
        >
          Subscribe
        </button>
      </CustomBlock>
    </FormBlock>
  </CustomBlock>
  // <Section className="secondary-lighter--sbg">
  //   <ContentBlock>
  //     <FormBlock
  //       className="js-cm-form"
  //       id="subForm"
  //       action="https://www.createsend.com/t/subscribeerror?description="
  //       method="post"
  //       data-id="5B5E7037DA78A748374AD499497E309ED6F6FD68776C34FA561D340520E3FF91A67E0E0AB26A3BE89FBAD5DAB61508C34514AF9EEA093D37D9CA99C33C13C3B5"
  //     >
  //       <CustomBlock>
  //         <CustomBlock>
  //           <InputBlock
  //             placeholder="Enter your email"
  //             inputRightButtonText="Sign Up"
  //             inputRightButtonBackgroundColor="lighter-blue--sbg circle"
  //             inputRightButtonTextColor="info--clr"
  //             inputRightButtonOnClick={() => {
  //               document.querySelector('#emailCampaignSubmitBtn').click();
  //             }}
  //             backgroundColor="white--sbg"
  //             autoComplete="Email"
  //             aria-label="Email"
  //             className="js-cm-email-input qa-input-email circle"
  //             id="fieldEmail"
  //             maxLength="200"
  //             name="cm-btuukkl-btuukkl"
  //             required
  //             type="email"
  //           />

  //         </CustomBlock>
  //       </CustomBlock>
  //       <button
  //         id="emailCampaignSubmitBtn"
  //         type="submit"
  //         style={{ display: 'none' }}
  //       >
  //         Subscribe
  //       </button>
  //     </FormBlock>
  //   </ContentBlock>
  // </Section>
);

export default EmailBlastSignUpPage;