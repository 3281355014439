import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'ProductType/';

const getAllProductTypes = async (isActive) => axiosInstance.get(`${ENDPOINTORIGIN}GetAllProductTypes?isActive=${isActive}`, constants.REQUIRE_INTERCEPTORS);

const getProductTypesById = async (productTypeId) => axiosInstance.get(`${ENDPOINTORIGIN}GetProductTypeById?productTypeId=${productTypeId}`, constants.REQUIRE_INTERCEPTORS);

const createProductType = async (productType) => axiosInstance.post(`${ENDPOINTORIGIN}CreateProductType`, productType, constants.REQUIRE_INTERCEPTORS);

const updateProductTypes = async (productType) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateProductTypes`, productType, constants.REQUIRE_INTERCEPTORS);

const sortProductTypes = async (sortOrders) => axiosInstance.put(`${ENDPOINTORIGIN}SortProductTypes`, sortOrders, constants.REQUIRE_INTERCEPTORS);

export {
  getAllProductTypes,
  getProductTypesById,
  createProductType,
  updateProductTypes,
  sortProductTypes
};