/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-alert */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
import { SwatchesPicker } from 'react-color';
import {
  DragDropContext,
  Droppable,
  Draggable
} from 'react-beautiful-dnd';
// ICONS
import { MdOutlinePlaylistAdd } from 'react-icons/md';
import { FaSquare } from 'react-icons/fa';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import FormBlock from '../../../../components/FormBlock';
import ContentHeader from '../../../../components/ContentHeader';
import InputBlock from '../../../../components/InputBlock';
import TextAreaBlock from '../../../../components/TextAreaBlock';
import SelectBlock from '../../../../components/SelectBlock';
import CheckboxBlock from '../../../../components/CheckboxBlock';
import Button from '../../../../components/Button';
import Overlay from '../../../../components/Overlay';
import ModalBlock from '../../../../components/ModalBlock';
import Image from '../../../../components/Image';
import FileUploadBlock from '../../../../components/FileUploadBlock';
import FixedActionsBar from '../../../../components/FixedActionsBar';
// ASSETS
// SERVICES AND HELPERS
import * as helper from '../../../../helpers/helper';
import * as blogService from '../../../../services/cms/blogService';
import * as contentSectionService from '../../../../services/cms/contentSectionService';
import BlogValidator from '../../../../helpers/validators/cms/blog/BlogValidator';
// REDUX
import * as auth from '../../../../redux/authRedux';
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModalRedux from '../../../../redux/confirmModalRedux';

const intialBlogModel = {
  id: 0,
  name: '',
  url: '',
  isBannerVisible: true,
  bannerImageFilePath: '',
  bannerColor: '#000000',
  keywords: '',
  description: '',
  isActive: true,
  bannerImage: null,
  contentSections: []
};

const typeSelectionOptions = [
  {
    label: 'Banner',
    value: 'BANNER'
  },
  {
    label: 'Content Section',
    value: 'CONTENTSECTION'
  }
];

const BlogManagementPage = (props) => {
  const { showAlert, history, showConfirmModal, hideConfirmModal } = props;
  const { blogId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSortModalOpen, setIsSortModalOpen] = useState(false);
  const [file, setFile] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [contentBannerTypeOption, setContentBannerTypeOption] = useState([]);
  const [selectedContentBannerTypeOption, setSelectedContentBannerTypeOption] = useState(null);
  const [blogModel, setBlogModel] = useState(intialBlogModel);
  const [sortList, setSortList] = useState([]);
  const iconSize = 22;
  const iconColor = 'white--clr';

  useEffect(() => {
    loadOptionsAndPopulateForm();
    setContentBannerTypeOption([]);
    setSelectedContentBannerTypeOption([]);
  }, [blogId]);

  useEffect(() => {
    setSelectedContentBannerTypeOption(null);
    setContentBannerTypeOption([]);
    if (selectedOption) {
      getOptions(selectedOption.value);
    }
  }, [selectedOption]);

  useEffect(() => {
    setSortList(blogModel.contentSections.sort((a, b) => a - b));
  }, [blogModel.contentSections]);

  const formik = useFormik({
    initialValues: blogModel,
    validationSchema: BlogValidator,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (blogId) {
        updateBlog(values);
      } else {
        createBlog(values);
      }
    },
  });

  const loadOptionsAndPopulateForm = async () => {
    if (blogId > 0) {
      getBlog();
    }
  };

  const getOptions = (type) => {
    setIsLoading(true);

    contentSectionService.getContentSectionOptionsForBlog(blogId, type).then((res) => {
      setContentBannerTypeOption(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const getBlog = async () => {
    setIsLoading(true);

    blogService.getBlogById(blogId).then((res) => {
      setBlogModel(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const createBlog = (model) => {
    setIsLoading(true);

    blogService.createBlog(helper.convertJsonToFormData(
      {
        ...model,
        bannerImage: file.length > 0 ? file[0].file : null
      }
    )).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push(`/cms/management/blog/edit/${res.id}`);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const updateBlog = (model) => {
    setIsLoading(true);

    if (file.length > 0) {
      model = { ...model, bannerImage: file[0].file };
    }
    blogService.updateBlog(helper.convertJsonToFormData(model)).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push('/cms/management/blogs');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const hideContentSectionModal = () => {
    setSelectedOption(null);
    setSelectedContentBannerTypeOption(null);
    setContentBannerTypeOption([]);

    setIsModalVisible(false);
  };

  const addContentSection = () => {
    if (selectedContentBannerTypeOption && selectedOption) {
      setIsLoading(true);

      blogService.assignContentSectionToBlog(blogId, selectedContentBannerTypeOption.value, selectedOption.value).then((res) => {
        getBlog();
        showAlert({ text: res.message, state: 'success' });
        hideContentSectionModal();
      }).catch((ex) => {
        showAlert({ text: ex.message, state: 'warning' });
      }).finally(() => setIsLoading(false));
    } else {
      showAlert({ text: 'Please select both type & component', state: 'warning' });
    }
  };

  const deleteBlogContent = (id) => {
    setIsLoading(true);

    blogService.unassignContentSection(id).then((res) => {
      getBlog();
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => {
      setIsLoading(false);
      hideConfirmModal();
    });
  };

  const sortBlogContents = () => {
    setIsLoading(true);

    blogService.sortBlogContentSections(sortList).then((res) => {
      getBlog();
      showAlert({ text: res.message, state: 'success' });
      setIsSortModalOpen(false);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const tempArr = helper.reOrderDnd(
      sortList,
      result.source.index,
      result.destination.index
    );

    setSortList(tempArr);
  };

  const deleteBannerImage = () => {
    blogService.deleteBannerImage(blogId).then((res) => {
      getBlog();
      showAlert({ text: res.message, state: 'success' });
      hideConfirmModal();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: false
  };

  const options = {
    selectableRows: 'none',
    download: false,
    print: false,
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="View"
              className="info--bg"
              size="xxs"
              onClick={() => {
                const model = blogModel.contentSections[dataIndex];
                history.push(`/cms/management/${(model.bannerId > 0) ? 'banner' : 'content-section'}/edit/${(model.bannerId > 0) ? model.bannerId : model.contentSectionId}`);
              }}
            />

            <Button
              text="Remove"
              className="danger--bg"
              size="xxs"
              onClick={() => {
                showConfirmModal({
                  title: 'Remove Blog Content',
                  text: 'Are you sure you want to remove this content?',
                  rightBtnText: 'Confirm',
                  btnAction: () => {
                    deleteBlogContent(blogModel.contentSections[dataIndex].id);
                  }
                });
              }}
            />
          </CustomBlock>
        )
      }
    },
    {
      name: 'name',
      label: 'Name',
      options: columnOptions,
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => (<p>{helper.mapContentSectionName(blogModel.contentSections[dataIndex].type)}</p>)
      },
    },
  ];

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <FormBlock className="content-container--card-style--with-shadow mb-120" onSubmit={formik.submitForm}>
              {/* BASIC INFORMATION */}
              <Section hasNoContainer>
                <ContentBlock>
                  <ContentHeader
                    title={blogId ? 'Blog Details' : 'New Blog'}
                    headerSize="lg"
                    primaryButtonText={blogId ? 'Preview Blog' : ''}
                    primaryButtonOnClick={() => {
                      history.push('/preview', {
                        url: formik.values.url,
                        title: formik.values.name,
                        id: blogId,
                        isBlog: true
                      });
                    }}
                  />
                </ContentBlock>

                <ContentBlock>
                  <ContentHeader
                    title="Basic Information"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    label="Blog Name"
                    placeholder="e.g. Services Blog"
                    isRequired
                    errorMessage={formik.errors.name}
                    inputState={`${helper.getInputClasses(formik, 'name')}`}
                    {...formik.getFieldProps('name')}
                  />
                </ContentBlock>

                <ContentBlock cols={12}>
                  <InputBlock
                    label="URL"
                    placeholder="Begin every URL with a / e.g. /blog-name"
                    isRequired
                    errorMessage={formik.errors.url}
                    inputState={`${helper.getInputClasses(formik, 'url')}`}
                    {...formik.getFieldProps('url')}
                  />
                </ContentBlock>
                <ContentBlock cols={3} className="mt-15">
                  <CheckboxBlock
                    label="Is Blog Active?"
                    id="isActive"
                    {...formik.getFieldProps('isActive')}
                    isChecked={formik.values.isActive}
                  />
                </ContentBlock>

                <ContentBlock cols={3} className="mt-15">
                  <CheckboxBlock
                    label="Is Banner Visible?"
                    id="isBannerVisible"
                    {...formik.getFieldProps('isBannerVisible')}
                    isChecked={formik.values.isBannerVisible}
                  />
                </ContentBlock>
              </Section>

              {/* PAGE BANNER */}
              <Section hasNoContainer className="mt-60">
                <ContentBlock>
                  <ContentHeader
                    title="Blog Banner"
                    subtitle="Upload an image for the blog banner or select a solid color"
                    headerSize="md"
                    subtitleSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                </ContentBlock>

                <ContentBlock cols={6}>
                  {
                    blogModel.bannerImageFilePath
                      ? (
                        <>
                          <CustomBlock className="flex-center pt-15 pb-30">
                            <Image
                              source={`${process.env.REACT_APP_API_URL}Attachments/${blogModel.bannerImageFilePath}`}
                              className="pl-10 pr-10"
                            />
                          </CustomBlock>

                          <FileUploadBlock onupdatefiles={setFile} labelIdle="Replace image" />

                          <CustomBlock className="flex-center">
                            <Button
                              text="Remove Banner Image"
                              className="danger--bg"
                              size="xxs"
                              onClick={() => showConfirmModal(
                                {
                                  title: 'Remove Banner Image',
                                  text: 'Are you sure you want to remove this banner Image?',
                                  rightBtnText: 'Confirm',
                                  btnAction: () => {
                                    deleteBannerImage();
                                  }
                                }
                              )}
                            />
                          </CustomBlock>
                        </>
                      ) : (
                        <FileUploadBlock
                          labelIdle="Upload image here"
                          onupdatefiles={setFile}
                        />
                      )
                  }

                </ContentBlock>

                <ContentBlock cols={6}>
                  <InputBlock
                    label="Banner Color"
                    placeholder="Type color HEX Code e.g. #FFFFFF or select colors below"
                    iconRight={<FaSquare size={35} color={formik.values.bannerColor} />}
                    errorMessage={formik.errors.bannerColor}
                    inputState={`${helper.getInputClasses(formik, 'bannerColor')}`}
                    {...formik.getFieldProps('bannerColor')}
                  />

                  <CustomBlock className="ml-10 mr-10">
                    <SwatchesPicker
                      onChangeComplete={(c) => {
                        formik.setFieldValue('bannerColor', c.hex);
                      }}
                      className="w-100"
                    />
                  </CustomBlock>
                </ContentBlock>
              </Section>

              {/* SEO INFORMATION */}
              <Section hasNoContainer className="mt-60">
                <ContentBlock>
                  <ContentHeader
                    title="Search Engine Optimization"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                </ContentBlock>

                <ContentBlock cols={6}>
                  <TextAreaBlock
                    label="Keywords"
                    placeholder="e.g. construction services, home renovation, custom home construction"
                    className="sm"
                    errorMessage={formik.errors.keywords}
                    inputState={`${helper.getInputClasses(formik, 'keywords')}`}
                    {...formik.getFieldProps('keywords')}
                  />
                </ContentBlock>

                <ContentBlock cols={6}>
                  <TextAreaBlock
                    label="Description"
                    placeholder="e.g. Fast and reliable construction services! Local resources and 24/7 support."
                    className="sm"
                    errorMessage={formik.errors.description}
                    inputState={`${helper.getInputClasses(formik, 'description')}`}
                    {...formik.getFieldProps('description')}
                  />
                </ContentBlock>
              </Section>

              {/* CONTENT */}
              {
                blogId
                && (
                  <Section hasNoContainer className="mt-60">
                    <ContentBlock>
                      <ContentHeader
                        title="Blog Content"
                        subtitle="Select from components to create a new content section or choose from available content sections"
                        headerSize="lg"
                        subtitleSize="md"
                        className="pb-15"
                        primaryButtonText="Add Content"
                        primaryButtonIconLeft={<MdOutlinePlaylistAdd className={iconColor} size={iconSize} />}
                        primaryButtonOnClick={() => setIsModalVisible(true)}
                        secondaryButtonText="Sort Items"
                        secondaryButtonOnClick={() => setIsSortModalOpen(true)}
                      />
                    </ContentBlock>

                    <ContentBlock>
                      <MUIDataTable
                        data={blogModel.contentSections}
                        columns={columns}
                        options={options}
                      />
                    </ContentBlock>
                  </Section>
                )
              }

              {/* PAGE ACTIONS */}
              <FixedActionsBar
                primaryActionText={blogId > 0 ? 'Save Changes' : 'Create Blog'}
                primaryActionColor="primary--bg"
                primaryActionOnClick={formik.handleSubmit}
                secondaryActionText="Cancel"
                secondaryActionTo="/cms/management/blogs"
                optionalActionText={blogId > 0 ? 'Return to Listing' : ''}
                optionalActionTo="/cms/management/blogs"
              />
            </FormBlock>
          </ContentBlock>
        </Section>

        {/* ADD Content Section MODAL */}
        <ModalBlock
          hasCloseAction
          centered
          isVisible={isModalVisible}
          size="md"
          contentHeader="Add Content Section"
          primaryModalActionText="Add"
          primaryModalActionColor="primary--bg"
          primaryModalActionOnClick={addContentSection}
          secondaryModalActionText="Create New"
          secondaryModalActionColor="secondary--bg"
          secondaryModalActionOnClick={() => {
            if (selectedOption !== null) {
              history.push(`/cms/management/${(selectedOption.value === 'BANNER') ? 'banner' : 'content-section'}/blogId/${blogId}`);
            } else {
              showAlert({ text: 'Please select type of Component you want to create', state: 'warning' });
            }
          }}
          onHide={hideContentSectionModal}
        >
          <Section hasNoContainer>
            <ContentBlock>
              <SelectBlock
                label="Type Of Component"
                placeholder="Select type of Component"
                options={typeSelectionOptions}
                value={selectedOption}
                onChange={setSelectedOption}
              />
            </ContentBlock>

            <ContentBlock className="mt-10">
              <SelectBlock
                label="Component"
                isDisabled={!selectedOption}
                value={selectedContentBannerTypeOption}
                placeholder="Select a Component"
                options={contentBannerTypeOption}
                onChange={setSelectedContentBannerTypeOption}
              />
            </ContentBlock>
          </Section>
        </ModalBlock>

        {/* SORT MODAL */}
        <ModalBlock
          hasCloseAction
          centered
          isVisible={isSortModalOpen}
          size="md"
          contentHeader="Sort Sections"
          primaryModalActionText="Sort"
          primaryModalActionOnClick={sortBlogContents}
          onHide={() => {
            setIsSortModalOpen(false);
          }}
        >
          <Section hasNoContainer>
            {
              sortList.length > 0
                ? (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(droppableProvided) => (
                        <div
                          ref={droppableProvided.innerRef}
                        >
                          {sortList.map((item, index) => (
                            <Draggable
                              key={item.id} draggableId={`${index}`}
                              index={index}
                            >
                              {(draggableProvided) => (
                                <div
                                  key={`index_${item.id}`}
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                  {...draggableProvided.dragHandleProps}
                                  className="content-container--card-style light-grey--sbg pt-15 pb-15 pl-20 pr-20 mb-15"
                                >
                                  <p className="main-font fw-500">{item.name}</p>
                                </div>

                              )}
                            </Draggable>
                          ))}
                          {droppableProvided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                ) : (
                  <CustomBlock className="content-container--card-style light-grey--sbg pt-15 pb-15 pl-20 pr-20 mb-15">
                    <p className="main-font fw-500">No Items Available</p>
                  </CustomBlock>
                )
            }
          </Section>
        </ModalBlock>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...auth.actions,
  ...alert.actions,
  ...confirmModalRedux.actions
})(BlogManagementPage);