// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Button from './Button';

const TextBlock = (props) => {
  const {
    type,
    text,
    link,
    containerClassName,
    className,
    position,
    color,
    actionText,
    actionTextColor,
    actionBackgroundColor,
    actionSize,
    actionIconRight,
    onClick,
    isTargetBlank,
    isPadded,
    isUppercase,
    hasAction,
    isHTML
  } = props;

  const textPositions = {
    left: 'left',
    center: 'center',
    right: 'right'
  };

  return (
    <CustomBlock className="text-block-container ">
      <CustomBlock className={`text-block ${position && textPositions[position]} ${containerClassName} ${hasAction ? 'has-action' : ''}`}>
        {
          type === 'header'
          && (
            <p className={`header ${isPadded ? 'padded' : ''} ${isUppercase ? 'uppercase' : ''} ${className} ${color}`}>
              {text}
            </p>
          )
        }

        {
          type === 'subheader'
          && (
            <p className={`subheader ${isPadded ? 'padded' : ''} ${isUppercase ? 'uppercase' : ''} ${className} ${color}`}>
              {text}
            </p>
          )
        }

        {
          isHTML ? (<div dangerouslySetInnerHTML={{ __html: text }} />) : (type === 'description' && <pre className={`description ${className} ${color}`}>{text}</pre>)
        }

        {
          type === 'link'
          && (
            <a
              href={link} target={isTargetBlank ? '_blank' : '_self'}
              className={`link ${className} ${color}`}
              rel="noreferrer"
            >
              {text}
            </a>
          )
        }
      </CustomBlock>
      {
        (hasAction && actionText !== '')
        && (
          <CustomBlock className="text-block-action-container">
            <Button
              text={actionText}
              className={`text-block-action ${actionTextColor} ${actionBackgroundColor}`}
              onClick={onClick}
              size={actionSize}
              iconRight={actionIconRight}
            />
          </CustomBlock>
        )
      }
    </CustomBlock>
  );
};

TextBlock.propTypes = {
  // MAIN PROPS
  type: PropTypes.string.isRequired,
  text: PropTypes.string,
  position: PropTypes.string,
  color: PropTypes.string,
  containerClassName: PropTypes.string,
  className: PropTypes.string,

  // LINK PROPS
  link: PropTypes.string,

  // ACTION PROPS
  actionText: PropTypes.string,
  actionTextColor: PropTypes.string,
  actionBackgroundColor: PropTypes.string,
  actionSize: PropTypes.string,
  actionIconRight: PropTypes.any,

  // BOOLEAN PROPS
  isTargetBlank: PropTypes.bool,
  isPadded: PropTypes.bool,
  isUppercase: PropTypes.bool,
  hasAction: PropTypes.bool,
  isHTML: PropTypes.bool,

  // FUNCTION PROPS
  onClick: PropTypes.func,
};

TextBlock.defaultProps = {
  // MAIN PROPS
  text: '',
  position: 'left',
  color: 'dark--clr',
  containerClassName: '',
  className: '',

  // LINK PROPS
  link: '#',

  // ACTION PROPS
  actionText: '',
  actionTextColor: 'secondary--clr',
  actionBackgroundColor: '',
  actionSize: '',
  actionIconRight: null,

  // BOOLEAN PROPS
  isTargetBlank: false,
  isPadded: false,
  isUppercase: false,
  hasAction: false,
  isHTML: false,

  // FUNCTION PROPS
  onClick: null,
};

export default TextBlock;