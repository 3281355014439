/* eslint-disable no-use-before-define */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-param-reassign */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// import {
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem
// } from '@material-ui/core';
// ICONS
import { IoMdPricetag } from 'react-icons/io';
// CUSTOM COMPONENTS
import DateFilterComponent from '../../../../../components/DateFilterComponent';
import Section from '../../../../../components/Section';
import ContentBlock from '../../../../../components/ContentBlock';
import CustomBlock from '../../../../../components/CustomBlock';
import FormBlock from '../../../../../components/FormBlock';
import ContentHeader from '../../../../../components/ContentHeader';
import Button from '../../../../../components/Button';
import ModalBlock from '../../../../../components/ModalBlock';
import InputBlock from '../../../../../components/InputBlock';
import FileUploadBlock from '../../../../../components/FileUploadBlock';
import Image from '../../../../../components/Image';
import CheckboxBlock from '../../../../../components/CheckboxBlock';
import Overlay from '../../../../../components/Overlay';
// ASSETS
// SERVICES AND HELPERS
import * as helper from '../../../../../helpers/helper';
import * as productService from '../../../../../services/inventory/productService';
// REDUX
import * as alert from '../../../../../redux/alertToastRedux';

const ProductListingPage = (props) => {
  const { auth, showAlert } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNewItem, setIsNewItem] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [products, setProducts] = useState([]);
  const [productToDeleteId, setProductToDeleteId] = useState(-1);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = () => {
    setIsLoading(true);
    productService.getAllProducts('').then((res) => {
      setProducts(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const deleteProduct = () => {
    productService.deleteProduct(productToDeleteId).then((res) => {
      showAlert({ text: res.message, state: 'success' });

      getProducts();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setProductToDeleteId(-1);
    });
  };

  const iconSize = 22;
  const iconColor = 'white--clr';

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true
  };

  const options = {
    selectableRows: 'none',
    download: true,
    print: false,
    rowsPerPage: 100,
    jumpToPage: true,
    textLabels: { pagination: { jumpToPage: 'Page No:' } }
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="View"
              className="primary--bg ml-0"
              size="xxs"
              // to={`/admin/management/product-deal/edit/${products[dataIndex].id}`}
              onClick={() => {
                window.open(`/admin/management/product-deal/edit/${products[dataIndex].id}`, '_blank');
              }}
            />

            {
              (auth.isAdmin || auth.user.id === products[dataIndex].addedById)
              && (
                <Button
                  text="Delete"
                  className="danger--bg"
                  size="xxs"
                  onClick={() => {
                    setProductToDeleteId(products[dataIndex].id);
                  }}
                />
              )
            }
          </CustomBlock>
        )
      }
    },
    {
      name: 'previewImage',
      label: 'Preview Image',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <CustomBlock className="small-image-container">
              <Image
                source={`${process.env.REACT_APP_API_URL}Attachments/${products[dataIndex].imageUrl}`}
              />
            </CustomBlock>
          </CustomBlock>
        )
      }
    },
    {
      name: 'name',
      label: 'Product Name',
      options: columnOptions,
    },
    {
      name: 'category',
      label: 'Category',
      options: columnOptions
    },
    {
      name: 'productTypes',
      label: 'Product Type',
      options: columnOptions
    },
    {
      name: 'promoCode',
      label: 'Promo Code',
      options: columnOptions
    },
    {
      name: 'discountPercent',
      label: 'Discount %',
      options: {
        selectableRows: 'none',
        download: true,
        print: false,
        customBodyRenderLite: (dataIndex) => <p>{`${Math.ceil(((products[dataIndex].previousPrice - products[dataIndex].currentPrice) / products[dataIndex].previousPrice) * 100)}%`}</p>
      }
    },
    {
      name: 'currentPrice',
      label: 'Current Price',
      options: {
        selectableRows: 'none',
        download: true,
        print: false,
        customBodyRenderLite: (dataIndex) => (
          <p>
            $
            {' '}
            {products[dataIndex].currentPrice.toFixed(2)}
          </p>
        )
      }
    },
    {
      name: 'previousPrice',
      label: 'Previous Price',
      options: {
        selectableRows: 'none',
        download: true,
        print: false,
        customBodyRenderLite: (dataIndex) => (
          <p>
            $
            {' '}
            {products[dataIndex].previousPrice.toFixed(2)}
          </p>
        )
      }
    },
    {
      name: 'addedBy',
      label: 'Posted By',
      options: columnOptions
    },
    {
      name: 'dateTimeAdded',
      label: 'Posted On',
      options: {
        ...columnOptions,
        filter: true,
        sort: true,
        filterType: 'custom',
        customFilterListRender: (v) => helper.dateFilterListRenderer(v),
        filterOptions: {
          names: [],
          logic(date, filters) {
            return helper.dateFilterLogic(date, filters);
          },
          display: (filterList, onChange, index, column) => (
            <DateFilterComponent
              name="Posted On"
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
            />
          )
        }
      }
    },
    {
      name: 'dateTimeModified',
      label: 'Last Updated On',
      options: {
        ...columnOptions,
        filter: true,
        sort: true,
        filterType: 'custom',
        customFilterListRender: (v) => helper.dateFilterListRenderer(v),
        filterOptions: {
          names: [],
          logic(date, filters) {
            return helper.dateFilterLogic(date, filters);
          },
          display: (filterList, onChange, index, column) => (
            <DateFilterComponent
              name="Last Updated On"
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
            />
          )
        }
      }
    },
    {
      name: 'dealStartsIn',
      label: 'Starts On',
      options: columnOptions
    },
    {
      name: 'dealExpiresIn',
      label: 'Expires In',
      options: columnOptions
    },
    {
      name: 'isActive',
      label: 'Is Active?',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => (<p>{products[dataIndex].isActive ? 'Yes' : 'No'}</p>),
      }
      // ...columnOptions

      // filterType: 'custom',
      // display: (filterList, onChange, index, column) => {
      //   const optionValues = [
      //     'Active',
      //     'In Active'
      //   ];
      //   return (
      //     <FormControl>
      //       <InputLabel htmlFor="select-status">Status</InputLabel>
      //       <Select
      //         labelId="select-status"
      //         id="select-status"
      //         value={filterList[index]}
      //         onChange={(event) => {
      //           filterList[index] = event.target.value;
      //           onChange(filterList[index], index, column);
      //         }}
      //       >
      //         {optionValues.map((item) => (
      //           <MenuItem key={item} value={item}>
      //             {item}
      //           </MenuItem>
      //         ))}
      //       </Select>
      //     </FormControl>
      //   );
      // }
    },
  ];

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Product Deals and Promo Codes"
                headerSize="lg"
                primaryButtonText="Create New Deal "
                primaryButtonIconLeft={<IoMdPricetag className={iconColor} size={iconSize} />}
                primaryButtonTo="/admin/management/product-deal/create"
              />

              <CustomBlock>
                <MUIDataTable
                  data={products}
                  columns={columns}
                  options={options}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      {/* VIEW/UPDATE MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={isModalVisible}
        size="md"
        contentHeader={isNewItem ? 'New Category' : 'Update Details'}
        primaryModalActionText={isNewItem ? 'Add' : 'Update'}
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={() => { }}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="danger--bg"
        onHide={() => {
          setIsModalVisible(false);
          setTimeout(() => {
            setIsNewItem(false);
          }, 500);
        }}
      >
        <FormBlock>
          <Section hasNoContainer>
            <ContentBlock>
              <InputBlock
                label="Category Name"
              />
            </ContentBlock>

            <ContentBlock>
              <FileUploadBlock />
            </ContentBlock>

            <ContentBlock>
              <CheckboxBlock
                label="Send notification immediately?"
                onClick={() => {
                  setIsChecked(!isChecked);
                }}
                defaultChecked={isChecked}
              />
            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock>

      {/* CONFIRM DELETE MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={productToDeleteId > 0}
        size="md"
        contentHeader="Delete Deal"
        contentDescription="Are you sure you want to delete this deal?"
        primaryModalActionText="Delete"
        primaryModalActionColor="danger--bg"
        primaryModalActionOnClick={() => {
          deleteProduct();
        }}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="grey--bg"
        onHide={() => {
          setProductToDeleteId(-1);
        }}
      />
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });
export default connect(mapStateFromProps, { ...alert.actions })(ProductListingPage);