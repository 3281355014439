/* eslint-disable react/self-closing-comp */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
/* eslint-disable react/no-unstable-nested-components */
// DEPENDENCIES
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as _ from 'lodash';
// ICONS
import { TiArrowSortedDown } from 'react-icons/ti';
import { RiInstagramLine } from 'react-icons/ri';
import { MdMailOutline } from 'react-icons/md';
import { HiMail } from 'react-icons/hi';
import { FiFacebook, FiMenu } from 'react-icons/fi';
import { FaFacebookF } from 'react-icons/fa';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Button from './Button';
import Image from './Image';
import Chip from './Chip';
import SelectBlock from './SelectBlock';
import DrawerNavigation from './DrawerNavigation';
// SERVICES
import * as homeService from '../services/cms/homeService';

const HeaderNavigation = (props) => {
  const {
    auth,
    emailAddress,
    backgroundColor,
    menuItemsBlockBackgroundColor,
    menuItemColor,
    menuToggleIconColor,
    menuToggleIconSize,
    mobileMenuItemColor,
    submenuBlockBackgroundColor,
    submenuItemColor,
    logo,
    icon,
    stores,
    categories,
    featuredSections,
    iconSize,
    iconAppearance,
    socialIconColor,
    socialIconBackgroundColor,
    primarySocialMediaLink,
    secondarySocialMediaLink,
    hasSearchBlock,
    menuItems,
    hasChipFilter,
    hasRoundCorners,
    isSubmenuFullWidth,
    history
  } = props;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMobileSubmenuVisible, setIsMobileSubmenuVisible] = useState(false);

  const popularSelections = [
    { id: 1, name: 'Filter 1', url: '' },
    { id: 2, name: 'Filter 2', url: '' },
    { id: 3, name: 'Filter 3', url: '' },
    { id: 4, name: 'Filter 4', url: '' },
    { id: 5, name: 'Filter 5', url: '' },
  ];

  const iconAppearanceOptions = {
    square: 'square',
    round: 'round',
    circle: 'circle',
  };

  const openInNewTab = (link) => {
    window.open(link, '_blank');
  };

  const handleMenuItemClick = (menuItem) => {
    if (menuItem.pageUrl && menuItem.pageUrl !== '') {
      if (menuItem.openInNewTab) {
        openInNewTab(menuItem.pageUrl);
      } else {
        history.push(menuItem.pageUrl);
      }
    } else if (menuItem.customLink) {
      if (menuItem.openInNewTab) {
        openInNewTab(menuItem.customLink);
      } else {
        history.push(menuItem.customLink);
      }
    }

    setTimeout(() => {
      setIsDrawerOpen(false);
    }, 200);
  };

  const getOptionsForKeyword = (keyword, callBack) => {
    homeService.searchProductByKeyword(keyword).then((res) => callBack(res))
      .catch(() => { }).finally(() => { });
  };

  const loadSuggestions = _.debounce(getOptionsForKeyword, 500);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const navigateToPage = (page) => {
    history.push(page);

    setTimeout(() => {
      setIsDrawerOpen(false);
    }, 200);
  };

  const toggleMobileSubmenu = () => {
    setIsMobileSubmenuVisible(!isMobileSubmenuVisible);
  };

  return (
    <>
      {/* MOBILE NAVIGATION */}
      <CustomBlock className="mobile-navigation">
        <CustomBlock className={`navigation-block ${backgroundColor}`}>
          {/* LOGO */}
          <CustomBlock className="logo-block">
            <Image
              source={icon}
              to="/"
            />
          </CustomBlock>

          {/* ICONS */}
          <CustomBlock className="icons-block">
            {/* SOCIAL ICONS */}
            <button
              onClick={() => openInNewTab(primarySocialMediaLink)}
              className="social-icon icon"
            >
              <FaFacebookF className={menuToggleIconColor} size={menuToggleIconSize - 10} />
            </button>

            <button
              onClick={() => window.open(`mailto:${emailAddress}`, '_self')}
              className="social-icon icon"
            >
              <HiMail className={menuToggleIconColor} size={menuToggleIconSize} />
            </button>

            {/* MENU TOGGLE */}
            <button
              className="menu-toggle icon"
              onClick={() => {
                toggleDrawer();
              }}
            >
              <FiMenu className="secondary--clr" size={menuToggleIconSize} />
            </button>
          </CustomBlock>
        </CustomBlock>

        {
          hasSearchBlock
          && (
            <CustomBlock className={`features-block ${backgroundColor}`}>
              <CustomBlock className="search-block">
                <SelectBlock
                  isAsync
                  placeholder="Search deals, promo codes and more..."
                  backgroundColor="lighter-grey--sbg"
                  value={[]}
                  defaultOptions={[]}
                  loadOptions={loadSuggestions}
                  noOptionsMessage={(e) => {
                    return (e.inputValue !== '') ? 'No Deals Available' : 'Start Typing to search deals';
                  }}
                  formatOptionLabel={(option) => (
                    <CustomBlock className="search-line-item">
                      <CustomBlock className="image-container">
                        <CustomBlock
                          className="line-item-image"
                          style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}Attachments/${option.filePath})` }}
                        />
                      </CustomBlock>

                      <CustomBlock className="label-container">
                        <p className="line-item-label">{option.label}</p>
                      </CustomBlock>
                    </CustomBlock>
                  )}
                  onChange={(e) => {
                    history.push(`/deal/details/${e.value}`);
                  }}
                />
              </CustomBlock>
            </CustomBlock>
          )
        }
      </CustomBlock>

      {/* HEADER NAVIGATION */}
      <CustomBlock className="header-navigation">
        <CustomBlock className={`features-block ${backgroundColor}`}>
          {/* LOGO */}
          <CustomBlock className="logo-block">
            <Image
              source={`${process.env.REACT_APP_API_URL}Attachments/${logo}`}
              to="/"
            />
          </CustomBlock>

          {/* SEARCH */}
          {
            hasSearchBlock
            && (
              <CustomBlock className="search-block">
                <SelectBlock
                  isAsync
                  placeholder="Search deals, discounts and promo codes"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();

                      history.push('/deals', {
                        predefinedFilter: {
                          sortBy: '',
                          priceRange: '',
                          categories: null,
                          searchTerm: e.target.value,
                          productTypes: '',
                          featuredSections: null,
                          skip: 0
                        }
                      });

                      e.target.blur();
                    }
                  }}
                  autoBlur
                  backgroundColor="white--sbg"
                  value={[]}
                  defaultOptions={[]}
                  loadOptions={loadSuggestions}
                  noOptionsMessage={(e) => {
                    return (e.inputValue !== '') ? 'No Deals Available' : 'Start Typing or Press enter to search deals';
                  }}
                  formatOptionLabel={(option) => (
                    <CustomBlock className="content-container--actions flex-start pl-0 mt-0 mb-10">
                      <CustomBlock className="smaller-image-container">
                        <Image
                          source={`${process.env.REACT_APP_API_URL}Attachments/${option.filePath}`}
                        />
                      </CustomBlock>
                      <p className="ml-15 fw-400">{option.label}</p>
                    </CustomBlock>
                  )}
                  onChange={(e) => {
                    history.push(`/deal/details/${e.value}`);
                  }}
                />
                {
                  hasChipFilter
                  && (
                    <CustomBlock className="chip-filter-block">
                      <CustomBlock className="chips-container">
                        {
                          popularSelections.map((item) => (
                            <Chip
                              hasBorder
                              label={item.name}
                              onClick={() => {

                              }}
                            />
                          ))
                        }
                      </CustomBlock>
                    </CustomBlock>
                  )
                }
              </CustomBlock>
            )
          }

          {/* ICONS BLOCK */}
          <CustomBlock className="icons-block">
            <button
              onClick={() => openInNewTab(primarySocialMediaLink)}
              className={`social-icon ${socialIconBackgroundColor} ${iconAppearance ? iconAppearanceOptions[iconAppearance] : 'square'}`}
            >
              <FiFacebook className={socialIconColor} size={iconSize} />
            </button>

            <button
              onClick={() => openInNewTab(secondarySocialMediaLink)}
              className={`social-icon ${socialIconBackgroundColor} ${iconAppearance ? iconAppearanceOptions[iconAppearance] : 'square'}`}
            >
              <RiInstagramLine className={socialIconColor} size={iconSize} />
            </button>

            <button
              onClick={() => window.open(`mailto:${emailAddress}`, '_self')}
              className={`social-icon ${socialIconBackgroundColor} ${iconAppearance ? iconAppearanceOptions[iconAppearance] : 'square'}`}
            >
              <MdMailOutline className={socialIconColor} size={iconSize} />
            </button>

            <Button
              text={auth.authToken ? 'Dashboard' : 'Login'}
              className="primary--clr primary-lighter--sbg"
              onClick={() => {
                openInNewTab(`${auth.authToken ? '/admin/management/dashboard' : '/login'}`);
              }}
            />
          </CustomBlock>
        </CustomBlock>

        {/* MENU ITEMS */}
        <CustomBlock className={`menu-items-block ${menuItemsBlockBackgroundColor}`}>
          <ul className="menu-items">
            <li>
              <Button
                text="Home"
                to="/home"
                className={`menu-item hover-animation--underline ${menuItemColor}`}
              />
            </li>
            <li>
              <Button
                text="Deals"
                to="/deals"
                className={`menu-item hover-animation--underline ${menuItemColor}`}
              />
            </li>
            <li className="has-submenu">
              <Button
                text="Stores"
                className={`menu-item hover-animation--underline ${menuItemColor}`}
                onClick={() => {
                  history.push('/stores');
                }}
              />
              <CustomBlock className={`submenu-block ${isSubmenuFullWidth ? 'full-width' : ''} ${submenuBlockBackgroundColor} ${hasRoundCorners ? 'round-corners' : ''}`}>
                <CustomBlock className="featured-block">
                  <CustomBlock className="featured-items">
                    {
                      stores.slice(0, 6).map((item) => (
                        <Image
                          key={item.id}
                          source={`${process.env.REACT_APP_API_URL}Attachments/${item.imageFilePath}`}
                          // title={item.name}
                          onClick={() => {
                            history.push('/deals', {
                              predefinedFilter: {
                                sortBy: '',
                                priceRange: '',
                                categories: [{ id: item.id, name: item.name }],
                                productTypes: null,
                                featuredSections: null,
                                searchTerm: '',
                                skip: 0
                              }
                            });
                          }}
                        />
                      ))
                    }
                  </CustomBlock>
                </CustomBlock>

                <ul className="submenu-items-block full-width">
                  {
                    stores.map((submenuItem) => (
                      <li key={submenuItem.id}>
                        <Button
                          text={submenuItem.name}
                          className={`menu-item ${submenuItemColor || menuItemColor}`}
                          onClick={() => {
                            history.push('/deals', {
                              predefinedFilter: {
                                sortBy: '',
                                priceRange: '',
                                categories: [{ id: submenuItem.id, name: submenuItem.name }],
                                productTypes: null,
                                featuredSections: null,
                                searchTerm: '',
                                skip: 0
                              }
                            });
                          }}
                        />
                      </li>
                    ))
                  }
                </ul>
              </CustomBlock>
            </li>

            <li className="has-submenu">
              <Button
                text="Categories"
                className={`menu-item hover-animation--underline ${menuItemColor}`}
                onClick={() => {
                  history.push('/categories');
                }}
              />
              <CustomBlock className={`submenu-block ${isSubmenuFullWidth ? 'full-width' : ''} ${submenuBlockBackgroundColor} ${hasRoundCorners ? 'round-corners' : ''}`}>
                <CustomBlock className="featured-block">
                  <CustomBlock className="featured-items">
                    {
                      featuredSections.slice(0, 4).map((item) => (
                        <Image
                          key={item.id}
                          source={`${process.env.REACT_APP_API_URL}Attachments/${item.imageFilePath}`}
                          title={item.name}
                          onClick={() => {
                            history.push('/deals', {
                              predefinedFilter: {
                                sortBy: '',
                                priceRange: '',
                                categories: null,
                                searchTerm: '',
                                productTypes: null,
                                featuredSections: [{ id: item.id, name: item.name }],
                                skip: 0
                              }
                            });
                          }}
                        />
                      ))
                    }
                  </CustomBlock>
                </CustomBlock>

                <ul className="submenu-items-block full-width extended">
                  {
                    categories.map((submenuItem) => (
                      <li key={submenuItem.id}>
                        <Button
                          text={submenuItem.name}
                          className={`menu-item ${submenuItemColor || menuItemColor}`}
                          onClick={() => {
                            history.push('/deals', {
                              predefinedFilter: {
                                sortBy: '',
                                priceRange: '',
                                categories: null,
                                searchTerm: '',
                                productTypes: [{ id: submenuItem.id, name: submenuItem.name }],
                                featuredSections: null,
                                skip: 0
                              }
                            });
                          }}
                        />
                      </li>
                    ))
                  }
                </ul>
              </CustomBlock>
            </li>
            {
              menuItems.map((menuItem) => (
                menuItem.assignedMenuItems.length > 0
                  ? (
                    <li className="has-submenu">
                      <Button
                        text={menuItem.name}
                        className={`menu-item hover-animation--underline ${menuItemColor}`}
                        onClick={() => {
                          handleMenuItemClick(menuItem);
                        }}
                      />
                      <CustomBlock className={`submenu-block default ${submenuBlockBackgroundColor} ${hasRoundCorners ? 'round-corners' : ''}`}>
                        <ul className="submenu-items-block default">
                          {
                            menuItem.assignedMenuItems.map((submenuItem) => (
                              <li key={submenuItem.id}>
                                <Button
                                  text={submenuItem.name}
                                  onClick={() => {
                                    handleMenuItemClick(submenuItem);
                                  }}
                                  className={`menu-item ${submenuItemColor || menuItemColor}`}
                                />
                              </li>
                            ))
                          }
                        </ul>
                      </CustomBlock>
                    </li>
                  ) : (

                    <li>
                      <Button
                        text={menuItem.name}
                        onClick={() => {
                          handleMenuItemClick(menuItem);
                        }}
                        className={`menu-item hover-animation--underline ${menuItemColor}`}
                      />
                    </li>
                  )
              ))
            }
          </ul>
        </CustomBlock>
      </CustomBlock>

      {/* DRAWER NAVIGATION */}
      <DrawerNavigation
        hasOverlay
        isOpen={isDrawerOpen}
        willCloseOnMaskPress
        onClick={() => {
          toggleDrawer();
        }}
      >
        <ul className="drawer-menu-items">
          <li>
            <Button
              text="Home"
              className={`menu-item menu-item hover-animation--underline ${mobileMenuItemColor}`}
              onClick={() => {
                navigateToPage('/');
              }}
            />
          </li>
          <li>
            <Button
              text="Deals"
              className={`menu-item menu-item hover-animation--underline ${mobileMenuItemColor}`}
              onClick={() => {
                navigateToPage('/deals');
              }}
            />
          </li>
          <li>
            <Button
              text="Stores"
              className={`menu-item menu-item hover-animation--underline ${mobileMenuItemColor}`}
              onClick={() => {
                navigateToPage('/stores');
              }}
            />
          </li>
          <li>
            <Button
              text="Categories"
              className={`menu-item menu-item hover-animation--underline ${mobileMenuItemColor}`}
              onClick={() => {
                navigateToPage('/categories');
              }}
            />
          </li>
          {
            menuItems.map((menuItem) => (
              menuItem.assignedMenuItems.length > 0
                ? (
                  <li className="has-submenu">
                    <Button
                      text={menuItem.name}
                      className={`menu-item hover-animation--underline ${mobileMenuItemColor}`}
                      iconRight={<TiArrowSortedDown size={25} className="danger--clr ml-15" />}
                      onClick={() => {
                        toggleMobileSubmenu();
                      }}
                    />
                    <CustomBlock className={`submenu-block default ${isMobileSubmenuVisible ? 'visible' : 'hidden'} ${submenuBlockBackgroundColor} ${hasRoundCorners ? 'round-corners' : ''}`}>
                      <ul className="submenu-items-block default">
                        {
                          menuItem.assignedMenuItems.map((submenuItem) => (
                            <li key={submenuItem.id}>
                              <Button
                                text={submenuItem.name}
                                className={`menu-item ${submenuItemColor || menuItemColor}`}
                                onClick={() => {
                                  handleMenuItemClick(submenuItem);
                                }}
                              />
                            </li>
                          ))
                        }
                      </ul>
                    </CustomBlock>
                  </li>
                ) : (

                  <li>
                    <Button
                      text={menuItem.name}
                      className={`menu-item hover-animation--underline ${mobileMenuItemColor}`}
                      onClick={() => {
                        handleMenuItemClick(menuItem);
                      }}
                    />
                  </li>
                )
            ))
          }
          <li>
            <Button
              text="Login"
              className={`menu-item menu-item hover-animation--underline ${mobileMenuItemColor}`}
              onClick={() => {
                navigateToPage('/login');
              }}
            />
          </li>
        </ul>
      </DrawerNavigation>
    </>
  );
};

HeaderNavigation.propTypes = {
  // MAIN PROPS
  logo: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  menuItemsBlockBackgroundColor: PropTypes.string,
  // to: PropTypes.string,

  // NAVIGATION PROPS
  menuItemColor: PropTypes.string,
  menuToggleIconColor: PropTypes.string,
  menuToggleIconSize: PropTypes.number,
  mobileMenuItemColor: PropTypes.string,

  // SUB MENU PROPS
  submenuBlockBackgroundColor: PropTypes.string,
  submenuItemColor: PropTypes.string,

  // SOCIAL MEDIA PROPS
  iconSize: PropTypes.number,
  iconAppearance: PropTypes.string.isRequired,
  socialIconBackgroundColor: PropTypes.string,
  socialIconColor: PropTypes.string,
  primarySocialMediaLink: PropTypes.string,
  secondarySocialMediaLink: PropTypes.string,

  // PRIMARY ICON PROPS
  // primaryActionIcon: PropTypes.any,
  // primaryActionIconText: PropTypes.string,

  // SECONDARY ICON PROPS
  // secondaryActionIcon: PropTypes.any,
  // secondaryActionIconText: PropTypes.string,

  // FUNCTION PROPS
  //   // primaryActionOnClick: PropTypes.func,
  // secondaryActionOnClick: PropTypes.func,

  // BOOLEANS PROPS
  hasSearchBlock: PropTypes.bool,

  // menuItems: PropTypes.array,
  hasChipFilter: PropTypes.bool,
  // hasFeaturedItems: PropTypes.bool,
  hasRoundCorners: PropTypes.bool,
  isSubmenuFullWidth: PropTypes.bool,
};

HeaderNavigation.defaultProps = {
  // MAIN PROPS
  backgroundColor: 'white--sbg',
  menuItemsBlockBackgroundColor: 'white--sbg',
  // to: '',

  // NAVIGATION PROPS
  menuItemColor: 'primary--clr',
  menuToggleIconColor: 'primary--clr',
  menuToggleIconSize: 35,
  mobileMenuItemColor: 'dark--clr',

  // SUB MENU PROPS
  submenuBlockBackgroundColor: 'white--sbg',
  submenuItemColor: 'primary--clr',

  // SOCIAL MEDIA PROPS
  iconSize: 22,
  socialIconColor: 'white--clr',
  socialIconBackgroundColor: 'primary--sbg',
  primarySocialMediaLink: '',
  secondarySocialMediaLink: '',

  // // BOOLEANS PROPS
  hasSearchBlock: false,
  hasChipFilter: false,
  // hasFeaturedItems: false,
  hasRoundCorners: false,
  isSubmenuFullWidth: false,
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, null)(HeaderNavigation);