// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';

const CustomBlock = (props) => {
  const { className, style, children } = props;

  return (
    <div className={`${className}`} style={style}>
      {children}
    </div>
  );
};

CustomBlock.propTypes = {
  // MAIN PROPS
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.any
};

CustomBlock.defaultProps = {
  className: '',
  style: {},
  children: null
};

export default CustomBlock;