/* eslint-disable max-len */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// import { Helmet } from 'react-helmet';
// COMPONENTS
// ICONS
import { AiFillHeart } from 'react-icons/ai';
import { BsCheckLg } from 'react-icons/bs';
import { FaRegCopy } from 'react-icons/fa';
// CUSTOM COMPONENTS
import Section from '../../../components/Section';
import ContentBlock from '../../../components/ContentBlock';
import CustomBlock from '../../../components/CustomBlock';
import Overlay from '../../../components/Overlay';
import TextBlock from '../../../components/TextBlock';
import ModalBlock from '../../../components/ModalBlock';
import Button from '../../../components/Button';
import Image from '../../../components/Image';
// ASSETS
// ASSETS - APP LINKS
import AppStoreBadge from '../../../assets/img/app-store-badge.svg';
import GooglePlayBadge from '../../../assets/img/google-play-badge.svg';
// SERVICES
import * as productService from '../../../services/inventory/productService';
import * as helper from '../../../helpers/helper';

const ProductDetailsPage = (props) => {
  const { cmsInfo } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSupportModalVisible, setIsSupportModalVisible] = useState(false);
  const [product, setProduct] = useState(null);
  const [previewImageIndex, setPreviewImageIndex] = useState(0);
  const [isPromoCodeCopied, setIsPromoCodeCopied] = useState(false);

  const { id } = useParams();
  useEffect(() => {
    getProductDetails();
  }, [id]);

  const copyPromoCode = (code) => {
    if (!code) {
      return;
    }
    helper.copyToClipBoard(code);
    setIsPromoCodeCopied(code);
  };

  const getProductDetails = () => {
    setIsLoading(true);
    productService.getProductDetailById(id).then((res) => {
      setProduct(res);
    }).catch(() => { }).finally(() => {
      setIsLoading(false);
    });
  };

  const navigateToLink = (link) => {
    window.open(link, '_blank');
  };

  const openInNewTab = (link) => {
    window.open(link, '_blank');
  };

  return (
    <>

      {isLoading && <Overlay hasLoader />}

      {
        product && (
          <>
            {/* <Helmet>
              <title>{product.name}</title>
              <meta property="og:title" content={product.name} />
              <meta property="og:description" content={product.name} />
              <meta property="og:type" content="image/jpeg" />
              <meta property="og:url" content={`https://bombdeals.com/deal/details/${product.id}`} />
              <meta property="og:image" content={`${process.env.REACT_APP_API_URL}Attachments/${product.images[0]}`} />
            </Helmet> */}
            <CustomBlock className="content-container--padded white--sbg">
              <CustomBlock className="content-wrapper">
                <CustomBlock className="product-details">
                  <CustomBlock className="mobile-actions">
                    <CustomBlock className="primary-action-block">
                      <button
                        className="primary-action"
                        onClick={() => {
                          openInNewTab(product.link);
                        }}
                      >
                        Go to Product Deal
                      </button>
                    </CustomBlock>

                    <CustomBlock className="secondary-action-block">
                      {/* LIKE */}
                      <button
                        className="like-block"
                        onClick={() => {
                          setIsModalVisible(true);
                        }}
                      >
                        <AiFillHeart size={27} className={`${product.likes < 1 ? 'light-grey--clr' : 'danger--clr'}`} />
                        <p className="like-count">{product.likes}</p>
                      </button>
                    </CustomBlock>
                  </CustomBlock>

                  {/* MOBILE TITLE */}
                  <TextBlock
                    type="description"
                    text={product.name}
                    className="product-title mobile"
                  />

                  <TextBlock
                    type="description"
                    text={product.category}
                    className="product-title small mobile "
                  />

                  {/* PRODUCT IMAGE */}
                  <CustomBlock className="product-image-section">
                    <CustomBlock className="product-images">
                      {/* PRODUCT IMAGE */}
                      <CustomBlock
                        className="product-image"
                        style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}Attachments/${product.images[previewImageIndex]})` }}
                      />

                      {/* PRODUCT THUMBNAILS */}
                      <CustomBlock className="product-image-thumbnails">
                        {
                          product.images.map((item, index) => (
                            <button
                              key={item.id}
                              className="thumbnail"
                              style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}Attachments/${product.images[index]})` }}
                              onMouseEnter={() => {
                                setPreviewImageIndex(index);
                              }}
                            >
                            </button>
                          ))
                        }
                      </CustomBlock>
                    </CustomBlock>

                    <CustomBlock className="actions-section">
                      <Button
                        text="Go to Product Deal"
                        className="secondary--bg top action"
                        size="sm"
                        onClick={() => {
                          openInNewTab(product.link);
                        }}
                      />
                    </CustomBlock>
                  </CustomBlock>

                  {/* PRODUCT DETAILS */}
                  <CustomBlock className="product-details-block">
                    {/* COUNTDOWN */}
                    {
                      helper.countdownMessage(product.dealExpiresIn) !== '' && (
                        <CustomBlock className="countdown-block">
                          <p className="countdown-text">{helper.countdownMessage(product.dealExpiresIn)}</p>
                        </CustomBlock>
                      )
                    }

                    {/* PRODUCT HEADER */}
                    <CustomBlock className="product-header">
                      <TextBlock
                        type="description"
                        text={product.name}
                        className="product-title"
                      />

                      {/* BADGE */}
                      {
                        product.badgeText
                        && (
                          <CustomBlock className="badge-section">
                            <CustomBlock
                              className="badge-text"
                              style={{ background: `${product.badgeColor}` }}
                            >
                              <p>{product.badgeText}</p>
                              <CustomBlock className="ribbon-tail" />
                            </CustomBlock>
                          </CustomBlock>
                        )
                      }

                      {/* PRODUCT CATEGORIES AND FEATURES */}
                      <CustomBlock className="feature-items">
                        <CustomBlock className="categories-and-filters">
                          <CustomBlock className="category">
                            <p className="store">{product.category}</p>
                          </CustomBlock>

                          <CustomBlock className="category">
                            <p className="type">{product.productType}</p>
                          </CustomBlock>
                        </CustomBlock>

                        {/* LIKE */}
                        <button
                          className="like-block"
                          onClick={() => {
                            setIsModalVisible(true);
                          }}
                        >
                          <AiFillHeart size={27} className={`${product.likes < 1 ? 'light-grey--clr' : 'danger--clr'}`} />
                          <p className="like-count">{product.likes}</p>
                        </button>
                      </CustomBlock>
                    </CustomBlock>

                    {/* PRICE DETAILS */}
                    <CustomBlock className="price-details">
                      <CustomBlock className="prices">
                        <CustomBlock className="current price-block">
                          <p className="small-dollar">$</p>
                          <p className="current-price price">{`${product.currentPrice.toFixed(2)}`}</p>
                        </CustomBlock>

                        <CustomBlock className="previous price-block">
                          <p className="small-dollar">$</p>
                          <p className="previous-price price">{`${product.previousPrice.toFixed(2)}`}</p>
                        </CustomBlock>

                        <CustomBlock className="discount price-block">
                          <p className="amount">{`(${(((product.previousPrice - product.currentPrice) / product.previousPrice) * 100).toFixed()}% Savings!)`}</p>
                        </CustomBlock>
                      </CustomBlock>

                      <button
                        className={`promo-code ${isPromoCodeCopied ? 'copied' : ''}`}
                        onClick={() => {
                          copyPromoCode(product.promoCode);
                        }}
                      >
                        {`${isPromoCodeCopied ? 'COPIED!' : 'PROMO CODE:'} ${product.promoCode || 'No Code Needed'}`}
                        {
                          isPromoCodeCopied
                            ? <BsCheckLg size={25} className="success--clr ml-10" />
                            : product.promoCode && !isPromoCodeCopied && <FaRegCopy size={30} className="grey--clr ml-10" />
                        }
                      </button>
                    </CustomBlock>

                    {/* POSTED ON */}

                    <CustomBlock className="ml-5 mt-20">
                      <TextBlock
                        type="description"
                        text={`Deal Posted On: ${product.dateTimeAdded}`}
                        className="size-xs fw-600 dark-blue--clr"
                      />
                    </CustomBlock>

                    {/* DESCRIPTION/ADDITIONAL DETAILS */}
                    <CustomBlock className="product-additional-details">
                      <TextBlock
                        type="description"
                        text="About this item"
                        className="size-md fw-600 mb-5 description-header"
                      />

                      <TextBlock
                        type="description"
                        text={product.additionalInformation}
                        className="description-text"
                      />
                    </CustomBlock>

                    {/* ACTIONS */}
                    <CustomBlock className="actions-section">
                      <Button
                        text="Go to Product Deal"
                        className="secondary--bg bottom action"
                        size="sm"
                        onClick={() => {
                          openInNewTab(product.link);
                        }}
                      />

                      <Button
                        className="grey--bg action secondary"
                        size="sm"
                        text="Report Issue"
                        onClick={() => {
                          setIsSupportModalVisible(true);
                        }}
                      />
                    </CustomBlock>

                  </CustomBlock>
                </CustomBlock>
              </CustomBlock>
            </CustomBlock>
          </>
        )
      }

      {/* DOWNLOAD APP PROMPT */}
      <ModalBlock
        isPrimaryActionHidden
        isSecondaryActionHidden
        isVisible={isModalVisible}
        size="lg"
        hasCloseAction
        secondaryModalActionText="Maybe Later"
        secondaryModalActionColor="primary--bg"
        onHide={() => {
          setIsModalVisible(false);
        }}
      >
        <Section hasNoContainer>
          <ContentBlock className="mb-15">
            <TextBlock
              isUppercase
              type="header"
              text="Like this deal?"
              color="secondary--clr"
              position="center"
            />
          </ContentBlock>

          <ContentBlock className="mb-60">
            <TextBlock
              isPadded
              type="description"
              text="Download our app to hit like on this deal and find more amazing discounts and promo codes!"
              className="size-md primary--clr"
              position="center"
            />
          </ContentBlock>

          <ContentBlock>
            <CustomBlock className="flex--around">
              <Image
                source={AppStoreBadge}
                onClick={() => navigateToLink(cmsInfo.appStoreLink)}
              />

              <Image
                source={GooglePlayBadge}
                onClick={() => navigateToLink(cmsInfo.googlePlayStoreLink)}
              />
            </CustomBlock>
          </ContentBlock>
        </Section>
      </ModalBlock>

      {/* SUPPORT */}
      <ModalBlock
        isSecondaryActionHidden
        hasCloseAction
        isActionsCentered
        isVisible={isSupportModalVisible}
        size="lg"
        buttonSize="sm"
        primaryModalActionText="Send Email"
        primaryModalActionColor="secondary--bg"
        primaryModalActionOnClick={() => {
          window.open(`mailto:${cmsInfo.emailAddress}`, '_self');
        }}
        onHide={() => {
          setIsSupportModalVisible(false);
        }}
      >
        <Section hasNoContainer>
          <ContentBlock className="mb-15">
            <TextBlock
              isUppercase
              type="header"
              text="Having Trouble?"
              color="secondary--clr"
              position="center"
            />
          </ContentBlock>

          <ContentBlock>
            <TextBlock
              isPadded
              type="description"
              text="Let us know! Click the button below to send us an email and let us know if this deal isn't working!"
              className="size-md primary--clr"
              position="center"
            />
          </ContentBlock>
        </Section>
      </ModalBlock>
    </>
  );
};

export default ProductDetailsPage;