import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Product/';

const getAllProducts = async (isActive) => axiosInstance.get(`${ENDPOINTORIGIN}GetAllProducts?isActive=${isActive}`, constants.REQUIRE_INTERCEPTORS);

const getProductById = async (productId) => axiosInstance.get(`${ENDPOINTORIGIN}GetProductById?productId=${productId}`, constants.REQUIRE_INTERCEPTORS);

const getProductBySearchTerm = async (searchTerm) => axiosInstance.get(`${ENDPOINTORIGIN}GetAllProductsBySearchTerm?searchTerm=${searchTerm}`, constants.REQUIRE_INTERCEPTORS);

const createProduct = async (product) => axiosInstance.post(`${ENDPOINTORIGIN}CreateProduct`, product, constants.REQUIRE_INTERCEPTORS);

const updateProduct = async (product) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateProduct`, product, constants.REQUIRE_INTERCEPTORS);

const deleteProduct = async (productId) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteProduct?productId=${productId}`, constants.REQUIRE_INTERCEPTORS);

const getProductsForDashboard = async (skip) => axiosInstance.get(`${ENDPOINTORIGIN}GetProductsForDashboard?skip=${skip}`, constants.REQUIRE_INTERCEPTORS);

const getProductDetailById = async (productId) => axiosInstance.get(`${ENDPOINTORIGIN}GetProductDetailById?productId=${productId}`, constants.REQUIRE_INTERCEPTORS);

export {
  getAllProducts,
  getProductById,
  getProductBySearchTerm,
  createProduct,
  updateProduct,
  deleteProduct,
  getProductsForDashboard,
  getProductDetailById
};