import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Category/';

const getAllCategories = async (isActive) => axiosInstance.get(`${ENDPOINTORIGIN}GetAllCategories?isActive=${isActive}`, constants.REQUIRE_INTERCEPTORS);

const getCategoryById = async (categoryId) => axiosInstance.get(`${ENDPOINTORIGIN}GetCategoryById?categoryId=${categoryId}`, constants.REQUIRE_INTERCEPTORS);

const createCategory = async (category) => axiosInstance.post(`${ENDPOINTORIGIN}CreateCategory`, category, constants.REQUIRE_INTERCEPTORS);

const updateCategory = async (category) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateCategory`, category, constants.REQUIRE_INTERCEPTORS);

const sortCategories = async (sortOrders) => axiosInstance.put(`${ENDPOINTORIGIN}SortCategories`, sortOrders, constants.REQUIRE_INTERCEPTORS);

export {
  getAllCategories,
  getCategoryById,
  createCategory,
  updateCategory,
  sortCategories
};