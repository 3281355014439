/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable react/no-unstable-nested-components */
// DEPENDENCIES
import React, {
  useEffect,
  useState
} from 'react';
import jwt_decode from 'jwt-decode';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { enableBodyScroll } from 'body-scroll-lock';
// ROUTES
import PrivateRoute from './routes/PrivateRoute';
import CMSRoute from './routes/CMSRoute';
// CUSTOM COMPONENTS
import ConfirmModal from './components/ConfirmModal';
import AlertToast from './components/AlertToast';
// AUTHENTICATION
import LoginPage from './pages/admin/authentication/LoginPage';
// MANAGEMENT PAGES - SYSTEM
import UserListingPage from './pages/admin/management/user/UserListingPage';
// PROJECT
// -- PRODUCTS
import ProductListingPage from './pages/admin/project/management/product/ProductListingPage';
import ProductManagementPage from './pages/admin/project/management/product/ProductManagementPage';
// -- STORES
import StoreListingPage from './pages/admin/project/management/store/StoreListingPage';
// -- PRODUCT CATEGORIES
import ProductCategoryListing from './pages/admin/project/management/product-category/ProductCategoryListing';
// -- FEATURED SECTIONS
import FeaturedSectionListingPage from './pages/admin/project/management/featured-section/FeaturedSectionListingPage';
import FeaturedSectionManagementPage from './pages/admin/project/management/featured-section/FeaturedSectionManagementPage';
// CMS
// -- MENU ITEMS
import MenuItemListingPage from './pages/cms/management/menu-item/MenuItemListingPage';
import MenuItemManagementPage from './pages/cms/management/menu-item/MenuItemManagementPage';
// -- CONTENT PAGES
import PageListingPage from './pages/cms/management/page/PageListingPage';
import PageManagementPage from './pages/cms/management/page/PageManagementPage';
// -- CONTENT SECTIONS
import ContentSectionListingPage from './pages/cms/management/content-section/ContentSectionListingPage';
import ContentSectionManagementPage from './pages/cms/management/content-section/ContentSectionManagementPage';
import BannerManagementPage from './pages/cms/management/content-section/BannerManagementPage';
// ASSETS AND RESOURCES
import AssetsAndResourcesPage from './pages/cms/management/resources/AssetsAndResourcesPage';
// FOOTER ITEMS
import FooterItemListingPage from './pages/cms/management/footer-items/FooterItemListingPage';
// CMS PAGES
import PreviewPage from './pages/cms/pages/PreviewPage';
import DashboardPage from './pages/admin/project/management/dashboard/DashboardPage';
// BLOGS
import BlogListingPage from './pages/cms/management/blog/BlogListingPage';
import BlogManagementPage from './pages/cms/management/blog/BlogManagementPage';
import ProductCategoriesPage from './pages/cms/pages/ProductCategoriesPage';
import StoresPage from './pages/cms/pages/StoresPage';
import FilteredDealsPage from './pages/cms/pages/FilteredDealsPage';
import ProductDetailsPage from './pages/cms/pages/ProductDetailsPage';
import BlogsAndReviewsListingPage from './pages/cms/pages/BlogsAndReviewsListingPage';
import DynamicCMSPage from './pages/cms/pages/DynamicCMSPage';
import Overlay from './components/Overlay';
// PUSH NOTIFICATION SERVICE
import PushNotificationHistoryListingPage from './pages/admin/project/management/push-notification-history/PushNotificationHistoryListingPage';
// SERVICES
import * as homeService from './services/cms/homeService';

const initialCMSInfo = {
  appStoreLink: '',
  city: '',
  companyIconPath: '',
  companyLogoPath: '',
  companyName: '',
  emailAddress: '',
  facebookLink: '',
  googleMapLink: '',
  googlePlayStoreLink: '',
  instagramLink: '',
  linkedInLink: '',
  menuItems: [],
  pages: [],
  footerItems: [],
  phoneNumber: '',
  primaryWeekDayHours: '',
  primaryWeekEndHours: '',
  secondaryWeekDayHours: '',
  secondaryWeekEndHours: '',
  state: '',
  streetAddress1: '',
  streetAddress2: '',
  twitterLink: '',
  youtubeLink: '',
  zipCode: '',
};

const Routes = (props) => {
  const { auth } = props;
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [cmsInfo, setCmsInfo] = useState(initialCMSInfo);
  const { pathname } = useLocation();

  useEffect(() => {
    fetchCMSInfoAndValidate();
  }, [auth]);

  const fetchCMSInfoAndValidate = async () => {
    setIsLoading(true);
    validateAccess();

    await homeService.getCMSInfo().then((res) => {
      setCmsInfo(res);
    }).catch().finally(() => {
      setIsLoading(false);
    });
  };

  const validateAccess = () => {
    if (auth.authToken !== '') {
      try {
        let decodedToken = jwt_decode(auth.authToken);
        setIsAuthorized(!!decodedToken);
      } catch (ex) {
        setIsAuthorized(false);
      }
    } else {
      setIsAuthorized(false);
    }
  };

  useEffect(() => {
    const body = document.querySelector('body');
    window.scrollTo(0, 0);
    enableBodyScroll(body);
  }, [pathname]);

  return (
    <>
      {
        cmsInfo.googleAnalyticsId && (
          <Helmet>
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${cmsInfo.googleAnalyticsId}`} />
            <script>
              {`window.dataLayer = window.dataLayer || [];
                  function gtag() { dataLayer.push(arguments); }
                  gtag('js', new Date());
                  gtag('config', '${cmsInfo.googleAnalyticsId}');`}
            </script>
          </Helmet>
        )
      }
      <AlertToast />
      <ConfirmModal />
      <>
        {
          isLoading ? <Overlay hasLoader />
            : (
              <Switch>
                <Redirect
                  exact
                  from="/"
                  to="/home"
                />
                <Route
                  path="/login"
                  component={LoginPage}
                />

                <Route
                  path="/preview"
                  component={() => <PreviewPage cmsInfo={cmsInfo} />}
                />

                {/* CMS PAGES */}

                {/* DYNAMIC CMS ROUTING */}
                {
                  cmsInfo.pages.map((page) => (
                    <CMSRoute
                      key={page.id}
                      path={page.url}
                      component={DynamicCMSPage}
                      title={page.title}
                      dynamicPageId={page.id}
                      isBlog={page.isBlog}
                      cmsInfo={cmsInfo}
                    />
                  ))
                }
                {/* <CMSRoute
                path="/home"
                component={HomePage}
                title="Homepage"
                cmsInfo={cmsInfo}
              /> */}
                <CMSRoute
                  path="/blogs-and-reviews"
                  component={BlogsAndReviewsListingPage}
                  title="Blogs and Reviews"
                  cmsInfo={cmsInfo}
                />

                <CMSRoute
                  path="/stores"
                  component={StoresPage}
                  title="Stores"
                  cmsInfo={cmsInfo}
                />

                <CMSRoute
                  path="/categories"
                  component={ProductCategoriesPage}
                  title="Product Categories"
                  cmsInfo={cmsInfo}
                />

                <CMSRoute
                  path="/deals/:featured/:featuredId"
                  component={FilteredDealsPage}
                  title="Deals"
                  cmsInfo={cmsInfo}
                />

                <CMSRoute
                  path="/deals/"
                  component={FilteredDealsPage}
                  title="Deals"
                  cmsInfo={cmsInfo}
                />

                <CMSRoute
                  path="/deal/details/:id"
                  component={ProductDetailsPage}
                  title="Product Details"
                  cmsInfo={cmsInfo}
                />

                {
                  isAuthorized
                    ? (
                      <>

                        {/* FEATURED SECTIONS */}
                        <PrivateRoute
                          path="/admin/management/featured-sections"
                          component={FeaturedSectionListingPage}
                          title="Manage Featured Sections"
                        />

                        {/* FEATURED SECTIONS */}
                        <PrivateRoute
                          exact
                          path="/admin/management/featured-section/edit/:featuredSectionId"
                          component={FeaturedSectionManagementPage}
                          title="Manage Featured Sections"
                        />

                        <PrivateRoute
                          exact
                          path="/admin/management/featured-section"
                          component={FeaturedSectionManagementPage}
                          title="Manage Featured Section"
                        />

                        <PrivateRoute
                          path="/admin/management/dashboard"
                          component={DashboardPage}
                          title="Dashboard"
                        />

                        {/* PRODUCTS */}
                        <PrivateRoute
                          path="/admin/management/product-deals"
                          component={ProductListingPage}
                          title="Manage Deals and Promo Codes"
                        />

                        <PrivateRoute
                          path="/admin/management/product-deal/create"
                          component={ProductManagementPage}
                          title="New Product Deal"
                        />

                        <PrivateRoute
                          path="/admin/management/product-deal/edit/:productId"
                          component={ProductManagementPage}
                          title="Edit Product Deal"
                        />

                        {/* CATEGORIES */}
                        <PrivateRoute
                          path="/admin/management/stores"
                          component={StoreListingPage}
                          title="Manage Stores"
                        />

                        {/* PUSH NOTIFICATION HISTORY */}
                        <PrivateRoute
                          path="/admin/push-notification/history"
                          component={PushNotificationHistoryListingPage}
                          title="Push Notification"
                        />

                        {/* PRODUCT TYPES */}
                        <PrivateRoute
                          path="/admin/management/product-categories"
                          component={ProductCategoryListing}
                          title="Manage Product Categories"
                        />

                        {/* USERS */}
                        <PrivateRoute
                          path="/admin/management/users"
                          component={UserListingPage}
                          title="Manage Users"
                        />

                        {/* CMS */}

                        {/* MENU ITEMS */}
                        <PrivateRoute
                          path="/cms/management/menu-items"
                          component={MenuItemListingPage}
                          title="Manage Menu Items"
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/menu-item/edit/:menuItemId"
                          component={MenuItemManagementPage}
                          title="Edit Menu Item"
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/menu-item"
                          component={MenuItemManagementPage}
                          title="Manage Menu Item"
                        />

                        {/* PAGES */}
                        <PrivateRoute
                          path="/cms/management/pages"
                          component={PageListingPage}
                          title="Manage Pages"
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/page"
                          component={PageManagementPage}
                          title="Manage Page"
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/page/edit/:pageId"
                          component={PageManagementPage}
                          title="Manage Page"
                        />

                        {/* BLOGS */}
                        <PrivateRoute
                          path="/cms/management/blogs"
                          component={BlogListingPage}
                          title="Manage Blogs"
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/blog"
                          component={BlogManagementPage}
                          title="Manage Page"
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/blog/edit/:blogId"
                          component={BlogManagementPage}
                          title="Manage Blog"
                        />

                        {/* CONTENT SECTIONS */}
                        <PrivateRoute
                          exact
                          path="/cms/management/content-sections"
                          component={ContentSectionListingPage}
                          title="Manage Content Sections"
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/content-section"
                          component={ContentSectionManagementPage}
                          title="Manage Content Section"
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/content-section/pageId/:pageId"
                          component={ContentSectionManagementPage}
                          title="Manage Content Section"
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/content-section/blogId/:blogId"
                          component={ContentSectionManagementPage}
                          title="Manage Content Section"
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/content-section/edit/:contentSectionId"
                          component={ContentSectionManagementPage}
                          title="Manage Content Section"
                        />

                        {/* BANNER */}
                        <PrivateRoute
                          exact
                          path="/cms/management/banner"
                          component={BannerManagementPage}
                          title="Banner Management"
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/banner/pageId/:pageId"
                          component={BannerManagementPage}
                          title="Banner Management"
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/banner/blogId/:blogId"
                          component={BannerManagementPage}
                          title="Banner Management"
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/banner/edit/:bannerId"
                          component={BannerManagementPage}
                          title="Banner Management"
                        />

                        {/* ASSETS AND RESOURCES */}
                        <PrivateRoute
                          path="/cms/management/assets-and-resources"
                          component={AssetsAndResourcesPage}
                          title="Manage Assets, Links and Resources"
                        />

                        {/* FOOTER ITEMS */}
                        <PrivateRoute
                          path="/cms/management/footer-items"
                          component={FooterItemListingPage}
                          title="Footer Items"
                        />
                      </>
                    )
                    : <Redirect to="/login" />
                }
              </Switch>
            )
        }
      </>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, null)(Routes);