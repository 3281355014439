/* eslint-disable max-len */
/* eslint-disable react/self-closing-comp */
// DEPENDENCIES
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// COMPONENTS
// ICONS
import { AiFillHeart } from 'react-icons/ai';
import { BsCheckLg } from 'react-icons/bs';
import { FaRegCopy } from 'react-icons/fa';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import TextBlock from './TextBlock';
import Button from './Button';
import ModalBlock from './ModalBlock';
import Section from './Section';
import ContentBlock from './ContentBlock';
import Image from './Image';
// ASSETS - APP LINKS
import AppStoreBadge from '../assets/img/app-store-badge.svg';
import GooglePlayBadge from '../assets/img/google-play-badge.svg';

const ProductCard = (props) => {
  const {
    badgeText,
    badgeBackgroundColor,
    imageSource,
    title,
    currentPrice,
    previousPrice,
    discountAmount,
    countdownText,
    counterText,
    promoCode,
    primaryActionOnClick,
    secondaryActionOnClick,
    optionalActionOnClick,
    hasRoundCorners,
    isCopied,
    appStoreLink,
    googlePlayStoreLink
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const openInNewTab = (link) => {
    window.open(link, '_blank');
  };

  return (
    <>
      {/* DESKTOP */}
      <CustomBlock className={`product-card ${hasRoundCorners ? 'round-corners' : ''}`}>
        <button
          className="image-block"
          onClick={primaryActionOnClick}
          style={{ backgroundImage: `url(${imageSource})` }}
        >
          {
            badgeText
            && (
              <CustomBlock className={`badge ${badgeBackgroundColor}`} style={{ backgroundColor: badgeBackgroundColor }}>
                <p className="badge-text">{badgeText}</p>
              </CustomBlock>
            )
          }
        </button>

        <CustomBlock className="details-block">
          <CustomBlock className="text-block">
            <button onClick={primaryActionOnClick}>
              <TextBlock
                type="description"
                text={title}
                className="product-card-title"
              />
            </button>

            <CustomBlock className="price-block">
              <p className="price current-price">{currentPrice}</p>
              <CustomBlock className="savings-block">
                <p className="price previous-price">{previousPrice}</p>
                <p className="price discount">{discountAmount}</p>
              </CustomBlock>
            </CustomBlock>

            <button
              className="promo-code-container"
              onClick={secondaryActionOnClick}
            >
              <p className={`promo-code ${isCopied ? 'copied' : ''}`}>
                {promoCode || 'No Code Needed'}
                {
                  isCopied
                    ? <BsCheckLg className="success--clr ml-10" />
                    : promoCode && !isCopied && <FaRegCopy size={20} className="grey--clr ml-10" />
                }
              </p>
            </button>

            {
              (isCopied || !promoCode)
              && (
                <CustomBlock className="flex-center">
                  <Button
                    text="Get Deal Now!"
                    className="warning--clr underline-hover--visible"
                    onClick={optionalActionOnClick}
                  />
                </CustomBlock>
              )
            }
          </CustomBlock>
        </CustomBlock>

        <CustomBlock className="features-block">
          <CustomBlock className="countdown-block">
            <p className="countdown-text secondary--clr">{countdownText}</p>
          </CustomBlock>

          <button onClick={() => setIsModalVisible(true)} className="clickable-item">
            <AiFillHeart size={22} className={counterText < 1 ? 'light-grey--clr' : 'danger--clr'} />
            <p className="counter-text">{counterText}</p>
          </button>
        </CustomBlock>
      </CustomBlock>

      {/* MOBILE */}
      <button
        className={`product-card-vertical ${hasRoundCorners ? 'round-corners' : ''}`}
        onClick={primaryActionOnClick}
      >
        {/* IMAGE */}
        <CustomBlock className="image-block">
          <CustomBlock
            className="product-image"
            style={{ backgroundImage: `url(${imageSource})` }}
          />

          {/* DISCOUNT AMOUNT */}
          <CustomBlock className="discount-block">
            <p className="discount">{discountAmount}</p>
          </CustomBlock>
        </CustomBlock>

        <CustomBlock className="details-block">
          <CustomBlock className="like-block">
            <CustomBlock className="like-item">
              <AiFillHeart size={18} className={counterText < 1 ? 'light-grey--clr' : 'danger--clr'} />
              <p className="counter-text">
                {counterText}
              </p>
            </CustomBlock>
          </CustomBlock>

          {/* BADGE */}
          {
            badgeText
            && (
              <CustomBlock className="badge-container">
                <p className="badge-text" style={{ backgroundColor: badgeBackgroundColor }}>{badgeText}</p>
              </CustomBlock>
            )
          }

          <CustomBlock className="text-block">
            <TextBlock
              type="description"
              text={title}
              className={`product-card-title ${badgeText ? 'no-margin-top' : ''}`}
            />
          </CustomBlock>

          <CustomBlock className="price-block">
            <p className="price current-price">{currentPrice}</p>
            <CustomBlock className="savings-block">
              <p className="price previous-price">{previousPrice}</p>
            </CustomBlock>
          </CustomBlock>
        </CustomBlock>

        <CustomBlock className="features-block">
          <CustomBlock className="countdown-block">
            <p className="countdown-text secondary--clr">{countdownText}</p>
          </CustomBlock>

          {/* <CustomBlock className="like-item">
            <AiFillHeart size={18} className={counterText < 1 ? 'light-grey--clr' : 'danger--clr'} />
            <p className="counter-text">
              {counterText}
            </p>
          </CustomBlock> */}
        </CustomBlock>
      </button>

      {/* DOWNLOAD APP PROMPT */}
      <ModalBlock
        isPrimaryActionHidden
        isSecondaryActionHidden
        isVisible={isModalVisible}
        size="lg"
        hasCloseAction
        secondaryModalActionText="Maybe Later"
        secondaryModalActionColor="primary--bg"
        onHide={() => {
          setIsModalVisible(false);
        }}
      >
        <Section hasNoContainer>
          <ContentBlock className="mb-15">
            <TextBlock
              isUppercase
              type="header"
              text="Like this Deal?"
              color="secondary--clr"
              position="center"
            />
          </ContentBlock>

          <ContentBlock className="mb-60">
            <TextBlock
              isPadded
              type="description"
              text="Download our app to hit like on this deal and find more amazing discounts and promo codes!"
              className="size-md primary--clr"
              position="center"
            />
          </ContentBlock>

          <ContentBlock>
            <CustomBlock className="flex--around">
              <Image
                source={AppStoreBadge}
                onClick={() => openInNewTab(appStoreLink)}
              />

              <Image
                source={GooglePlayBadge}
                onClick={() => openInNewTab(googlePlayStoreLink)}
              />
            </CustomBlock>
          </ContentBlock>
        </Section>
      </ModalBlock>
    </>
  );
};

ProductCard.propTypes = {
  // MAIN PROPS
  title: PropTypes.string.isRequired,
  imageSource: PropTypes.number.isRequired,
  currentPrice: PropTypes.string.isRequired,
  previousPrice: PropTypes.string,
  discountAmount: PropTypes.string,
  promoCode: PropTypes.string,
  badgeText: PropTypes.string,
  badgeBackgroundColor: PropTypes.string,

  // COUNTER AND COUNTDOWN PROPS
  countdownText: PropTypes.string,
  counterText: PropTypes.number,

  // FUNCTION PROPS
  primaryActionOnClick: PropTypes.func,
  secondaryActionOnClick: PropTypes.func,
  optionalActionOnClick: PropTypes.func,

  // BOOLEAN PROPS
  hasRoundCorners: PropTypes.bool,
  isCopied: PropTypes.bool,
};

ProductCard.defaultProps = {
  // MAIN PROPS
  previousPrice: null,
  discountAmount: null,
  promoCode: '',
  badgeText: '',
  badgeBackgroundColor: '',

  // COUNTER AND COUNTDOWN PROPS
  countdownText: '',
  counterText: 0,

  // FUNCTION PROPS
  primaryActionOnClick: null,
  secondaryActionOnClick: null,
  optionalActionOnClick: null,

  // BOOLEAN PROPS
  hasRoundCorners: false,
  isCopied: false,
};

export default ProductCard;