/* eslint-disable max-len */
/* eslint-disable react/self-closing-comp */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React from 'react';
import { useHistory } from 'react-router-dom';
// COMPONENTS
// ICONS
// CUSTOM COMPONENTS
// import Section from '../../../components/Section';
// import ContentBlock from '../../../components/ContentBlock';
import CMSRoute from '../../../routes/CMSRoute';
import DynamicCMSPage from './DynamicCMSPage';
// ASSETS - APP LINKS
// HELPERS
// import constants from '../../../constants/constants';
// SERVICES

const PreviewPage = (props) => {
  const { cmsInfo } = props;
  const history = useHistory();

  const page = history.location.state;

  return (
    <CMSRoute
      path="/preview"
      component={DynamicCMSPage}
      title={page.title}
      dynamicPageId={page.id}
      isBlog={page.isBlog}
      cmsInfo={cmsInfo}
    />
  );
};

export default PreviewPage;